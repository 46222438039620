.python-online-container{
    margin-top: 120px !important;
    margin-bottom: 150px !important;
}

.img01-title-1 {
    position: absolute;
    color: white;
}

.img01-title-2 {
    position: absolute;
    color: white;
}

.python-online-container .trial-container {
    margin: 3vh 0 10vh 0;
    background-color: #1665A8;
    padding: 5vh;
}

.python-online-container .trial-container p {
    color: white !important;
}

@media (min-width: 320px) and (max-width: 374px) {
    .python-online-container{
        margin-top: 100px !important;
    }
    .python-online-img01-conatiner {
        position: relative;
    }
    .python-online-img01-conatiner img {
        width: 100%;
        position: relative;
    }
    .img01-title-1 {
        font-size: 9px !important;
        top: 110px;
        left: 15px;
    }
    .img01-title-2 {
        font-size: 8px !important;
        top: 130px;
        left: 15px;
    }
    .python-online-img02-conatiner {
        margin: 20px 0px;
        padding: 10px;
    }
    .python-online-img02-conatiner img {
        width: 100%;
    }
    .studyathome-conatiner {
        margin: 30px 10px;
    }
    .flow-container {
        margin: 0 auto;
        width: 100%;
    }
    .flow-container img {
        width: 100%;
    }
    .python-online-container .trial-container {
        margin: 100px 0 ;
    }
}

@media (min-width: 375px) and (max-width: 413px) {
    .python-online-container{
        margin-top: 100px !important;
    }
    .python-online-img01-conatiner {
        position: relative;
    }
    .python-online-img01-conatiner img {
        width: 100%;
        position: relative;
    }
    .img01-title-1 {
        font-size: 9px !important;
        top: 120px;
        left: 15px;
    }
    .img01-title-2 {
        font-size: 8px !important;
        top: 140px;
        left: 15px;
    }
    .python-online-img02-conatiner {
        margin: 20px 0px;
        padding: 10px;
    }
    .python-online-img02-conatiner img {
        width: 100%;
    }
    .studyathome-conatiner {
        margin: 30px 10px;
    }
    .flow-container {
        margin: 0 auto;
        width: 100%;
    }
    .flow-container img {
        width: 100%;
    }
    .python-online-container .trial-container {
        margin: 100px 0 ;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .python-online-container{
        margin-top: 100px !important;
    }
    .python-online-img01-conatiner {
        position: relative;
    }
    .python-online-img01-conatiner img {
        width: 100%;
        position: relative;
    }
    .img01-title-1 {
        font-size: 12px !important;
        top: 135px;
        left: 15px;
    }
    .img01-title-2 {
        font-size: 12px !important;
        top: 155px;
        left: 15px;
    }
    .python-online-img02-conatiner {
        margin: 20px 0px;
        padding: 10px;
    }
    .python-online-img02-conatiner img {
        width: 100%;
    }
    .studyathome-conatiner {
        margin: 30px 10px;
    }
    .flow-container {
        margin: 0 auto;
        width: 100%;
    }
    .flow-container img {
        width: 100%;
    }
    .python-online-container .trial-container {
        margin: 100px 0 ;
    }
}

@media (min-width: 429px) and (max-width: 450px) {
    .python-online-container{
        margin-top: 100px !important;
    }
    .python-online-img01-conatiner {
        position: relative;
    }
    .python-online-img01-conatiner img {
        width: 100%;
        position: relative;
    }
    .img01-title-1 {
        font-size: 13px !important;
        top: 135px;
        left: 15px;
    }
    .img01-title-2 {
        font-size: 13px !important;
        top: 155px;
        left: 15px;
    }
    .python-online-img02-conatiner {
        margin: 20px 0px;
        padding: 10px;
    }
    .python-online-img02-conatiner img {
        width: 100%;
    }
    .studyathome-conatiner {
        margin: 30px 10px;
    }
    .flow-container {
        margin: 0 auto;
        width: 100%;
    }
    .flow-container img {
        width: 100%;
    }
    .python-online-container .trial-container {
        margin: 100px 0 ;
    }
}

@media (min-width: 451px) and (max-width: 474px) {
    .python-online-container{
        margin-top: 100px !important;
    }
    .python-online-img01-conatiner {
        position: relative;
    }
    .python-online-img01-conatiner img {
        width: 100%;
        position: relative;
    }
    .img01-title-1 {
        font-size: 13px !important;
        top: 145px;
        left: 15px;
    }
    .img01-title-2 {
        font-size: 13px !important;
        top: 165px;
        left: 15px;
    }
    .python-online-img02-conatiner {
        margin: 20px 0px;
        padding: 10px;
    }
    .python-online-img02-conatiner img {
        width: 100%;
    }
    .studyathome-conatiner {
        margin: 30px 10px;
    }
    .flow-container {
        margin: 0 auto;
        width: 100%;
    }
    .flow-container img {
        width: 100%;
    }
    .python-online-container .trial-container {
        margin: 100px 0 ;
    }
}

@media (min-width: 475px) and (max-width: 520px) {
    .python-online-container{
        margin-top: 100px !important;
    }
    .python-online-img01-conatiner {
        position: relative;
    }
    .python-online-img01-conatiner img {
        width: 100%;
        position: relative;
    }
    .img01-title-1 {
        font-size: 13px !important;
        bottom: 40px;
        left: 15px;
    }
    .img01-title-2 {
        font-size: 13px !important;
        bottom: 30px;
        left: 15px;
    }
    .python-online-img02-conatiner {
        margin: 20px 0px;
        padding: 10px;
    }
    .python-online-img02-conatiner img {
        width: 100%;
    }
    .studyathome-conatiner {
        margin: 30px 10px;
    }
    .flow-container {
        margin: 0 auto;
        width: 100%;
    }
    .flow-container img {
        width: 100%;
    }
    .python-online-container .trial-container {
        margin: 100px 0 ;
    }
}

@media (min-width: 521px) and (max-width: 599px) {
    .python-online-container{
        margin-top: 100px !important;
    }
    .python-online-img01-conatiner {
        position: relative;
    }
    .python-online-img01-conatiner img {
        width: 100%;
        position: relative;
    }
    .img01-title-1 {
        font-size: 14px !important;
        bottom: 40px;
        left: 20px;
    }
    .img01-title-2 {
        font-size: 14px !important;
        bottom: 30px;
        left: 20px;
    }
    .python-online-img02-conatiner {
        margin: 20px 0px;
        padding: 20px;
    }
    .python-online-img02-conatiner img {
        width: 100%;
    }
    .studyathome-conatiner {
        margin: 30px 20px;
    }
    .flow-container {
        margin: 0 auto;
        width: 90%;
    }
    .flow-container img {
        width: 100%;
    }
    .python-online-container .trial-container {
        margin: 100px 0 ;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .python-online-container{
        margin-top: 100px !important;
    }
    .python-online-img01-conatiner {
        position: relative;
    }
    .python-online-img01-conatiner img {
        width: 100%;
        position: relative;
    }
    .img01-title-1 {
        font-size: 15px !important;
        bottom: 50px;
        left: 30px;
    }
    .img01-title-2 {
        font-size: 15px !important;
        bottom: 40px;
        left: 30px;
    }
    .python-online-img02-conatiner {
        margin: 20px 0px;
        padding: 30px;
    }
    .python-online-img02-conatiner img {
        width: 100%;
    }
    .studyathome-conatiner {
        margin: 30px 30px;
    }
    .flow-container {
        margin: 0 auto;
        width: 85%;
    }
    .flow-container img {
        width: 100%;
    }
    .python-online-container .trial-container {
        margin: 100px 0 ;
    }
}

@media (min-width: 700px) and (max-width: 959px) {
    .python-online-container{
        margin-top: 100px !important;
    }
    .python-online-img01-conatiner {
        position: relative;
    }
    .python-online-img01-conatiner img {
        width: 100%;
        position: relative;
    }
    .img01-title-1 {
        font-size: 18px !important;
        bottom: 50px;
        left: 30px;
    }
    .img01-title-2 {
        font-size: 18px !important;
        bottom: 40px;
        left: 30px;
    }
    .python-online-img02-conatiner {
        margin: 20px 0px;
        padding: 30px;
    }
    .python-online-img02-conatiner img {
        width: 100%;
    }
    .studyathome-conatiner {
        margin: 30px 30px;
    }
    .flow-container {
        margin: 0 auto;
        width: 80%;
    }
    .flow-container h1 {
        margin-bottom: 40px;
    }
    .flow-container img {
        width: 100%;
    }
    .python-online-container .trial-container {
        margin: 100px 0 ;
    }
}

@media (min-width: 960px) and (max-width: 1024px) {
    .python-online-container{
        margin-top: 100px !important;
    }
    .python-online-img01-conatiner {
        position: relative;
    }
    .python-online-img01-conatiner img {
        width: 100%;
        position: relative;
    }
    .img01-title-1 {
        font-size: 26px !important;
        bottom: 55px;
        left: 35px;
    }
    .img01-title-2 {
        font-size: 26px !important;
        bottom: 40px;
        left: 35px;
    }
    .online-demo-container{
        width: 80%;
        margin: 0 auto;
    }
    .python-online-img02-conatiner {
        margin: 20px 0px;
        padding: 40px;
    }
    .python-online-img02-conatiner img {
        width: 100%;
    }
    .studyathome-conatiner {
        margin: 30px 30px;
    }
    .flow-container {
        margin: 0 auto;
        width: 60%;
    }
    .flow-container h1 {
        margin-bottom: 40px;
    }
    .flow-container img {
        width: 100%;
    }
    .python-online-container .trial-container {
        margin: 100px 0 ;
    }
}

@media (min-width: 1025px) {
    .python-online-img01-conatiner {
        position: relative;
    }
    .python-online-img01-conatiner img {
        width: 100%;
        position: relative;
    }
    .img01-title-1 {
        font-size: 28px !important;
        bottom: 90px;
        left: 50px;
    }
    .img01-title-2 {
        font-size: 28px !important;
        bottom: 80px;
        left: 50px;
    }
    .online-demo-container {
        margin: 30px auto;
        width: 1000px ;
    }
    .flow-container {
        margin: 0 auto;
        width: 680px;
    }
    .flow-container h1 {
        margin-bottom: 50px;
    }
    .flow-container img {
        width: 100%;
    }
    .python-online-container .trial-container {
        margin: 100px auto 100px auto;
        padding: 20px 0 70px 0;
        width: 100%;
    } 
    .python-online-container .trial-text-container{
        width: 1000px;
        margin: 0 auto;
    }
    .python-online-img02-conatiner {
        margin-bottom: 100px;
        width: 100%;
        overflow: hidden;
    }
    .python-online-img02-conatiner img {
        width: 100%;
        object-fit: contain;
    }
}

@media (min-width: 1200px) {
    .img01-title-1 {
        font-size: 30px !important;
        bottom: 90px;
        left: 50px;
    }
    .img01-title-2 {
        font-size: 30px !important;
        bottom: 70px;
        left: 50px;
    }
    .python-online-container .trial-container {
        margin: 100px 0 ;
    }
}

@media (min-width: 1300px) {
    .img01-title-1 {
        font-size: 32px !important;
        bottom: 100px;
        left: 50px;
    }
    .img01-title-2 {
        font-size: 32px !important;
        bottom: 80px;
        left: 50px;
    }
    .python-online-container .trial-container {
        margin: 100px 0 ;
    }
}
