.aboutus-container{
    margin-top: 120px !important;
    z-index: 10;
}

.title-background {
    width: 100%;
    height: 550px;
    background-image: url('../../images/decorations/children.jpg');
    background-size: cover;
    background-position: center;
}

.title-background-text {
    width: 70%;
    margin: 0 auto;
    background-color: #1665A8;
    padding: 40px 50px;
    border-radius: 80px;
    opacity: 0.8 !important;
}

.title-background-text p {
    color: white !important;
}

.title-background h2 {
    margin-top: 150px;
    color: white;
    text-align: center;
}

/* part 2 of about */

.about-2-container {
    width: 100%;
    background-color: none !important;
    /* background-image: url('https://stratforddemo.files.wordpress.com/2019/08/academy-celebrate-celebration-267885-1-1.jpg');
    background-size: cover;
    background-attachment: fixed !important; */
}
.about-2-container img{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: -10 !important;
    bottom: 0;
}

.about-2 {
    background-color: #1665A8;
    height: 100%;
    opacity: 0.7 !important;
}

.about-2-text {
    width: 60%;
    margin: auto;
}

.about-2-text-1 h2 {
    color: white;
    text-align: center;
    margin-bottom: 30px;
}

.about-2-text-2 {
    color: white;
    padding: 3vh;
    border: 1px solid white;
}

.about-2-text-2 p {
    color: white !important;
}

.stories-1{
    background-color: #FFFAF0;

}

.stories-1 .MuiGrid-grid-md-6 {
    padding: 2vh;
}

.stories-1 img {
    width: 100%;
    object-fit: cover;
}

/* sorties part 2 */

.stories-2 {
    margin: 5vh;
    text-align: center !important;
    background-color: #FFFAF0;
}

/* sorties part 3 */

.stories-3 {
    margin: 5vh 0;
    padding: 5vh;
    text-align: center;
    background-color: rgb(236, 236, 236);
}

/* sorties part 4 */

.stories-4 {
    margin: 5vh auto;
    width: 300px;
    background-color: #FFFAF0;
}

.stories-4 img {
    width: 100%;
}

/* sorties part 5 */

.stories-5 {
    margin: 5vh;
}

.stories-5 p {
    margin-top: 10px;
}

.legend-time {
    text-align: end;
}

.all-stories{
    background-color: #FFFAF0;
}

@media (min-width: 320px) and (max-width: 374px) {
    .aboutus-container{
        margin-top: 100px !important;
    }
    .title-background {
        height: 480px;
    }
    .title-background-text {
        width: 300px;
        margin: 0 auto;
        padding: 28px 40px;
        border-radius: 80px;
    }
    .about-2-container {
        width: 100%;
        height: 1050px;
    }
    .about-2-container img{
       bottom: 0px;
    }
    .about-2-text {
        width: 95%;
        margin: auto;
    }
    .stories-1 {
        padding: 50px 10px 0 10px ;
    }
    .stories-1 h2 {
        margin-top: 0px;
        text-align: center;
    }
    .stories-1 .MuiGrid-grid-md-6 {
        padding: 0.5vh;
    }
    .stories-2 {
        margin: 5vh 20px;
    }
    .stories-2 h2 {
        margin-top: 1.5vh;
        text-align: center;
    }
    .stories-3 {
        margin: 5vh 0vh;
        padding: 5vh;
        text-align: center;
        background-color: rgb(236, 236, 236);
    }
    .stories-4 {
        margin: 5vh auto;
        width: 300px;
    }
    .stories-5 {
        width: 300px;
        margin: 0 auto !important;
        padding-bottom: 150px;
    }
}

@media (min-width: 330px) and (max-width: 369px) {
    .aboutus-container{
        margin-top: 100px !important;
    }
    .about-2-container {
        width: 100%;
        height: 1000px;
    } 
}


@media (min-width: 370px) and (max-width: 374px) {
    .aboutus-container{
        margin-top: 100px !important;
    }
    .about-2-container {
        width: 100%;
        height: 950px;
    } 
}

@media (min-width: 375px) and (max-width: 413px) {
    .aboutus-container{
        margin-top: 100px !important;
    }
    .title-background {
        height: 480px;
    }
    .title-background-text {
        width: 350px;
        margin: 0 auto;
        padding: 28px 40px;
        border-radius: 80px;
    }
    .about-2-container {
        width: 100%;
        height: 980px;
    }
    .about-2-text {
        width: 90%;
        margin: auto;
    }
    .stories-1 {
        padding: 50px 10px 0 10px;
    }
    .stories-1 h2 {
        margin-top: 0px;
        text-align: center;
    }
    .stories-1 .MuiGrid-grid-md-6 {
        padding: 0.5vh;
    }
    .stories-2 {
        margin: 20px 0px;
    }
    .stories-2 p{
        margin: 20px 20px;
    }
    .stories-2 h2 {
        margin-top: 1.5vh;
        text-align: center;
    }
    .stories-3 {
        margin: 5vh 0vh;
        padding: 5vh;
        text-align: center;
        background-color: rgb(236, 236, 236);
    }
    .stories-4 {
        margin: 5vh auto;
        width: 300px;
    }
    .stories-5 {
        width: 330px;
        margin: 0 auto !important;
        padding-bottom: 150px;
    }
}

@media (min-width: 400px) and (max-width: 413px) {
    .aboutus-container{
        margin-top: 100px !important;
    }
    .about-2-container {
        width: 100%;
        height: 920px;
    } 
}


@media (min-width: 414px) and (max-width: 428px) {
    .aboutus-container{
        margin-top: 100px !important;
    }
    .title-background {
        height: 480px;
    }
    .title-background-text {
        width: 350px;
        margin: 0 auto;
        padding: 28px 40px;
        border-radius: 80px;
    }
    .about-2-container {
        width: 100%;
        height: 950px;
    }
    .about-2-text {
        width: 360px;
        margin: auto;
    }
    .stories-1 {
        padding: 80px 10px 20px 10px;
    }
    .stories-1 h2 {
        margin-top: 0px;
        text-align: center;
    }
    .stories-1 .MuiGrid-grid-md-6 {
        padding: 0.5vh;
    }
    .stories-2 {
        margin: 5vh 1vh;
    }
    .stories-2 h2 {
        margin-top: 1.5vh;
        text-align: center;
    }
    .stories-3 {
        margin: 5vh 0vh;
        padding: 5vh;
        text-align: center;
        background-color: rgb(236, 236, 236);
    }
    .stories-4 {
        margin: 5vh auto;
        width: 320px;
    }
    .stories-5 {
        width: 380px;
        margin: 0 auto !important;
        padding-bottom: 150px;
    }
}


@media (min-width: 429px) and (max-width: 450px) {
    .aboutus-container{
        margin-top: 100px !important;
    }
    .title-background {
        height: 480px;
    }
    .title-background-text {
        width: 350px;
        margin: 0 auto;
        padding: 28px 40px;
        border-radius: 80px;
    }
    .about-2-container {
        width: 100%;
        height: 950px;
    }
    .about-2-text {
        width: 360px;
        margin: auto;
    }
    .stories-1 {
        padding: 80px 10px 20px 10px;
    }
    .stories-1 h2 {
        margin-top: 0px;
        text-align: center;
    }
    .stories-1 .MuiGrid-grid-md-6 {
        padding: 0.5vh;
    }
    .stories-2 {
        margin: 5vh 1vh;
        padding-bottom: 50px;
    }
    .stories-2 h2 {
        margin-top: 1.5vh;
        text-align: center;
    }
    .stories-3 {
        margin: 5vh 0vh;
        padding: 5vh;
        text-align: center;
        background-color: rgb(236, 236, 236);
    }
    .stories-4 {
        margin: 5vh auto;
        width: 350px;
    }
    .stories-5 {
        width: 380px;
        margin: 0 auto !important;
        padding-bottom: 150px;
    }
}


@media (min-width: 451px) and (max-width: 499px) {
    .aboutus-container{
        margin-top: 100px !important;
    }
    .title-background {
        height: 480px;
    }
    .title-background-text {
        width: 350px;
        margin: 0 auto;
        padding: 28px 40px;
        border-radius: 80px;
    }
    .about-2-container {
        height: 900px;
    }
    .about-2-text {
        width: 380px;
        margin: auto;
    }
    .stories-1 {
        padding: 80px 20px 20px 20px;
    }
    .stories-1 h2 {
        margin-top: 0px;
        text-align: center;
    }
    .stories-1 .MuiGrid-grid-md-6 {
        padding: 0.5vh;
    }
    .stories-2 {
        margin: 50px 20px;
        padding-bottom: 20px;
    }
    .stories-2 h2 {
        margin-top: 1.5vh;
        text-align: center;
    }
    .stories-3 {
        margin: 8vh 0vh;
        padding: 5vh;
        text-align: center;
        background-color: rgb(236, 236, 236);
    }
    .stories-4 {
        margin: 8vh auto;
        width: 350px;
    }
    .stories-5 {
        width: 380px;
        margin: 0 auto;
        padding-bottom: 150px;
    }
}

@media (min-width: 500px) and (max-width: 599px) {
    .aboutus-container{
        margin-top: 100px !important;
    }
    .title-background {
        height: 480px;
    }
    .title-background-text {
        width: 350px;
        margin: 0 auto;
        padding: 28px 40px;
        border-radius: 80px;
    }
    .about-2-container {
        height: 830px;
    }
    .about-2-text {
        width: 430px;
        margin: auto;
    }
    .stories-1 {
        padding: 80px 30px 10px 30px;
    }
    .stories-1 h2 {
        margin-top: 0px;
        text-align: center;
    }
    .stories-1 .MuiGrid-grid-md-6 {
        padding: 0.5vh;
    }
    .stories-2 {
        margin: 50px 30px;
    }
    .stories-2 h2 {
        margin-top: 1.5vh;
        text-align: center;
    }
    .stories-3 {
        margin: 8vh 0vh;
        padding: 5vh;
        text-align: center;
        background-color: rgb(236, 236, 236);
    }
    .stories-4 {
        margin: 8vh auto;
        width: 350px;
    }
    .stories-5 {
        width: 450px;
        margin: 0 auto !important;
        padding-bottom: 150px;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .aboutus-container{
        margin-top: 100px !important;
    }
    .title-background {
        height: 480px;
    }
    .title-background-text {
        width: 350px;
        margin: 0 auto;
        padding: 28px 40px;
        border-radius: 80px;
    }
    .about-2-container {
        height: 750px;
    }
    .about-2-text {
        width: 510px;
        margin: auto;
    }
    .stories-1 {
        padding: 80px 30px 10px 30px;
    }
    .stories-1 h2 {
        margin-top: 0px;
        text-align: center;
    }
    .stories-1 .MuiGrid-grid-md-6 {
        padding: 0.5vh;
    }
    .stories-2 {
        margin: 8vh 30px;
    }
    .stories-2 h2 {
        margin-top: 1.5vh;
        text-align: center;
    }
    .stories-3 {
        margin: 8vh 0vh;
        padding: 5vh;
        text-align: center;
        background-color: rgb(236, 236, 236);
    }
    .stories-4 {
        margin: 8vh auto;
        width: 450px;
    }
    .stories-5 {
        width: 480px;
        margin: 0 auto !important;
        padding-bottom: 150px;
    }
}


@media (min-width: 700px)  and (max-width: 844px){
    .aboutus-container{
        margin-top: 100px !important;
    }
    .title-background-text {
        width: 500px;
        padding: 25px 35px;
        border-radius: 80px;
    }
    .about-2-container {
        height: 630px;
    }
    .about-2-text {
        width: 650px;
        margin: auto;
    }
    .stories-1 {
        padding: 80px 50px 10px 50px ;
    }
    .stories-1 h2 {
        margin-top: 1.5vh;
        text-align: center;
    }
    .stories-2 {
        margin: 50px;
        padding-bottom: 30px;
    }
    .stories-2 h2 {
        margin-top: 1.5vh;
    }
    .stories-4 {
        margin: 8vh auto;
        width: 400px;
    }
    .stories-5 {
        width: 600px;
        margin: 0 auto !important;
        padding-bottom: 150px;

    }
}

@media (min-width: 845px) and (max-width: 959px){
    .aboutus-container{
        margin-top: 100px !important;
    }
    .title-background-text {
        width: 550px;
        padding: 25px 35px;
    }
    .about-2-container {
        height: 640px;
    }
    .about-2-text {
        width: 650px;
        margin: auto;
    }
    .stories-1 {
       padding: 100px 80px 20px 80px;
    }
    .stories-1 h2 {
        margin-top: 1.5vh;
        text-align: center;
    }
    .stories-2 {
        margin: 10vh 80px;
        padding: 2vh;
    }
    .stories-2 h2 {
        margin-top: 1.5vh;
        text-align: center;
    }
    .stories-3 {
        margin: 10vh 0;
        padding: 8vh;
    }
    .stories-4 {
        margin: 10vh auto;
        width: 400px;
    }
    .stories-5 {
        width: 780px;
        margin: 0 auto !important;
        padding-bottom: 150px;
    }
}

@media (min-width: 960px) and (max-width: 1000px){
    .aboutus-container{
        margin-top: 100px !important;
    }
    .title-background-text {
        width: 540px;
        padding: 25px 35px;
    }
    .about-2-container {
        height: 620px;
    }
    .about-2-text {
        width: 650px;
        margin: auto;
    }
    .stories-1 {
        padding: 100px 80px 10px 80px;
    }
    .stories-1 h2 {
        margin-top: 1.5vh;
        text-align: left !important;
    }
    .stories-2 {
        margin: 50px 80px;
        padding: 2vh;
    }
    .stories-2 h2 {
        margin-top: 1.5vh;
        text-align: center;
    }
    .stories-3 {
        margin: 10vh 0 15vh 0;
        padding: 80px;
    }
    .stories-4 {
        margin: 10vh auto;
        width: 400px;
    }
    .stories-5 {
        width: 780px;
        margin: 0 auto !important;
        padding-bottom: 150px;
    }
}


@media (min-width: 1001px) and (max-width: 1024px){
    .aboutus-container{
        margin-top: 100px !important;
    }
    .title-background-text {
        width: 540px;
        padding: 25px 35px;
    }
    .about-2-container {
        height: 790px;
    }
    .about-2-text {
        width: 650px;
        margin: auto;
    }
    .stories-1 {
        padding: 100px 80px 10px 80px;
    }
    .stories-1 h2 {
        margin-top: 1.5vh;
        text-align: left !important;
    }
    .stories-2 {
        margin: 50px 80px;
        padding: 2vh;
    }
    .stories-2 h2 {
        margin-top: 1.5vh;
        text-align: center;
    }
    .stories-3 {
        margin: 10vh 0 15vh 0;
        padding: 80px;
    }
    .stories-4 {
        margin: 10vh auto;
        width: 400px;
    }
    .stories-5 {
        width: 780px;
        margin: 0 auto !important;
        padding-bottom: 150px;
    }
}


@media (min-width: 1025px){
    .about-2-container {
        height: 830px;
    }
    .stories-1 {
        width: 1000px;
        margin: 0 auto;
        padding: 100px 0 10px 0;
    }
    .stories-2 {
        margin: 50px auto;
        padding: 2vh 0;
        width: 1000px;
    }
    .stories-3 {
        padding: 80px;
    }
    .stories-3-text{
        width: 1000px;
        margin: 0 auto;
    }
    .stories-4 {
        width: 600px;
        padding-top: 100px;
    }
    .stories-5 h2{
        margin-top: 100px;
        margin-bottom: 50px;
    }
    .stories-5 {
        width: 900px;
        margin: 0 auto !important;
        padding-bottom: 150px;
    }
}

@media (min-width: 1300px) {
    .about-2-container img{
        width: 100%;
        height: auto !important;
    }
}