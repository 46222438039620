header{
  position:fixed;
  top: 0;
  left: 0;
  padding: 0 100px;
  background-color: rgb(255, 250, 240) ;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  z-index: 999;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

header .logo {
  height: 60px;
  line-height: 120px;
  float: left;
}

header .logo img{
  height: 100%;
}

header nav{
  float: right;
  font-size: 16px !important;
}

header nav .fa-caret-down{
  margin-left: 10px;
}

header nav ul{
  margin: 0;
  padding: 0;
  display: flex;
  cursor: pointer;
}

header nav ul li{
  list-style: none;
  position: relative;
}

header nav ul li.sub-menu:before{
  position: absolute;
  line-height: 120px;
  color: #303030;
  right: 5px;
}

header nav ul li ul{
  position: absolute;
  margin-top: -30px !important;
  background-color: rgb(255, 250, 240) ;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

header nav ul li:hover ul{
  display: block;
}

header nav ul li ul li{
  display: block;
  width: 180px;
}

header nav ul li a{
  height: 120px;
  line-height: 120px;
  padding: 0 20px;
  text-decoration: none;
  display: block;
  color: #303030 !important;
  cursor: pointer;
}

header nav ul li ul li a{
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  text-decoration: none;
  display: block;
  color: #303030 !important;
  text-align: center;
}

header nav ul li a:hover,
header nav ul li a.active{
  color: #FBBC05 !important;
}

header nav ul li ul li a:hover,
header nav ul li ul li a.active{
  color: white !important;
  background-color: #FBBC05;
}

.menu-toggle{
  color: rgb(46, 46, 46);
  float: right;
  line-height: 80px;
  font-size: 24px;
  cursor: pointer;
  display: none;
}

@media (max-width: 600px) {
  .menu-toggle{
    display: block;
  }
  header{
    padding: 10px 20px;
    height: 100px;
  }
  header nav{
    display: none;
    position: absolute;
    width: 100%;
    height: calc(100vh - 50px);
    background-color: rgb(255, 252, 246) ;    
    top: 100px;
    left: -100%;
    transition: 0.5s;
    z-index: 999;
  }
  header .logo {
    height: 50px;
    line-height: 100px;
  }
  header .logo img{
    margin-bottom: 20px;
  }
  header nav.active{
    left: 0;
    display: block;
    transition: 0.5s;
  }
  header nav ul{
    display: block;
    text-align: center;
  }
  header nav ul li a{
    border-bottom: none;
    height: 60px;
    line-height: 60px;
    text-align: start;
  }
  header nav ul li ul{
    background-color: rgb(255, 252, 246) ;  
    margin-top: 0px !important;
    box-shadow: none;  
  }
  header nav ul li ul li a{
    border-bottom: none;
    color:#FBBC05 !important;
    text-align: start;
    margin-left: 20px;
    width: 92%;
  }
  header nav ul li:hover ul{
    position: relative;
  }
  header nav ul li ul li{
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 991px) {
  .menu-toggle{
    display: block;
  }
  header{
    padding: 10px 40px;
    height: 100px;
  }
  header nav{
    display: none;
    position: absolute;
    width: 100%;
    height: calc(100vh - 50px);
    background-color: rgb(255, 252, 246) ;    
    top: 100px;
    left: -100%;
    transition: 0.5s;
    z-index: 999;
  }
  header .logo {
    height: 50px;
    line-height: 100px;
  }
  header .logo img{
    margin-bottom: 20px;
  }
  header nav.active{
    left: 0;
    display: block;
    transition: 0.5s;
  }
  header nav ul{
    display: block;
    text-align: center;
  }
  header nav ul li a{
    border-bottom: none;
    height: 60px;
    line-height: 60px;
    text-align: start;
  }
  header nav ul li ul{
    background-color: rgb(255, 252, 246) ;  
    margin-top: 0px !important;
    box-shadow: none;  
  }
  header nav ul li ul li a{
    border-bottom: none;
    color:#FBBC05 !important;
    text-align: start;
    margin-left: 20px;
    width: 92%;
  }
  header nav ul li:hover ul{
    position: relative;
  }
  header nav ul li ul li{
    width: 100%;
  }
}


@media (min-width: 1025px) {
  header{
    padding: 0 5vw;
    width: 100%;
  }
}