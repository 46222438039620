.teachers-container {
    padding: 5vh;
    text-align: center;
    margin-top: 120px !important;
    padding-bottom: 150px !important;
}

.teachers-containter-title{
margin: 0 auto;
}


.teacher-title {
    text-align: start;
    margin-top: 5vh;
    margin-bottom: 2vh;
    color: #FBBC05;
}

.teacher-title h2 {
    color: #35ADD9 !important;
}

.teachers-container img {
    border-radius: 50%;
    border: 2px solid #35ADD9;
}




@media (min-width: 320px) and (max-width: 413px) {
    .teachers-container {
        padding: 10px;
    }
    .teachers-container img {
        width: 120px;
        height: 120px;
        padding: 2px;
    }
    .teachers-container p {
        font-size: 12px !important;
    }
    .teachers-container h3 {
        margin-top: 10px;
    }
    .teachers-container-title{
        margin: 10px 0 80px 0;
    }
    .teacher-title {
        margin-top: 50px !important;
        margin-bottom: 30px !important;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .teachers-container img {
        width: 160px;
        height: 160px;
        padding: 5px;
    }
    .teachers-container p {
        font-size: 12px !important;
    }
    .teachers-container h3 {
        margin-top: 10px;
    }
    .teachers-container-title{
        margin: 10px 0 80px 0;
    }
    .teacher-title {
        margin-top: 50px !important;
        margin-bottom: 30px !important;
    }
}

@media (min-width: 429px) and (max-width: 450px) {
    .teachers-container img {
        width: 160px;
        height: 160px;
        padding: 5px;
    }
    .teachers-container p {
        font-size: 12px !important;
    }
    .teachers-container h3 {
        margin-top: 10px;
    }
    .teachers-container-title{
        margin: 10px 0 80px 0;
    }
    .teacher-title {
        margin-top: 50px !important;
        margin-bottom: 30px !important;
    }
}

@media (min-width: 451px) and (max-width: 599px) {
    .teachers-container img {
        width: 150px;
        height: 150px;
        padding: 5px;
    }
    .teachers-container p {
        font-size: 12px !important;
    }
    .teachers-container h3 {
        margin-top: 10px;
    }
    .teachers-container-title{
        margin: 10px 0 80px 0;
    }
    .teacher-title {
        margin-top: 50px !important;
        margin-bottom: 30px !important;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .teachers-container img {
        width: 150px;
        height: 150px;
        padding: 5px;
    }
    .teachers-container p {
        font-size: 14px !important;
    }
    .teachers-container h3 {
        margin-top: 10px;
    }
    .teachers-container-title{
        margin: 10px 0 80px 0;
    }
    .teacher-title {
        margin-top: 50px !important;
        margin-bottom: 20px !important;
    }
}



@media (min-width: 700px) and (max-width: 799px) {
    .teachers-container img {
        width: 150px;
        height: 150px;
        padding: 5px;
    }
    .teachers-container p{
        font-size: 15px !important;
    }
    .teachers-container .MuiGrid-grid-md-3 {
        margin-top: 30px;
    }
    .teachers-container-title{
        margin: 50px 0 100px 0;
    }
    .teacher-title {
        margin-top: 50px !important;
        margin-bottom: 20px !important;
    }
}

@media (min-width: 800px) and (max-width: 899px) {
    .teachers-container img {
        width: 170px;
        height: 170px;
        padding: 5px;
    }
    .teachers-container p{
        font-size: 14px !important;
    }
    .teachers-container .MuiGrid-grid-md-3 {
        margin-top: 30px;
    }
    .teachers-container-title{
        margin: 30px 0 100px 0;
    }
    .teacher-title {
        margin-top: 80px !important;
        margin-bottom: 20px !important;
    }

}

@media (min-width: 900px) and (max-width: 1024px) {
    .teachers-container img {
        width: 180px;
        height: 180px;
        padding: 5px;
    }
    .teachers-container p{
        font-size: 15px !important;
    }
    .teachers-container .MuiGrid-grid-md-3 {
        margin-top: 30px;
    }
    .teachers-container-title{
        margin: 50px 0 100px 0;
    }
    .teacher-title {
        margin-top: 100px !important;
        margin-bottom: 20px !important;
    }
}

@media (min-width: 1025px) {
    .teachers-container{
        margin: 180px auto 50px auto !important;
        width: 1000px;
    }
    .teachers-container .teacher-title{
        margin-top: 150px;
    }
    .teachers-container img {
        width: 180px;
        height: 180px;
        padding: 5px;
    }
    .teachers-container p{
        font-size: 16px !important;
    }
    .teachers-container .MuiGrid-grid-md-3 {
        margin-top: 30px;
    }
}

@media (min-width: 1100px) {
    .teachers-container .teacher-title{
        margin-top: 150px;
    }
    .teachers-container img {
        width: 200px;
        height: 200px;
        padding: 5px;
    }
}


@media (min-width: 1200px) {
    .teachers-container .teacher-title{
        margin-top: 150px;
    }
    .teachers-container img {
        width: 200px;
        height: 200px;
        padding: 5px;
    }
}