.item-yellow {
    text-align: center;
    border: #FFDC48 solid 4px;
    border-radius: 20px;
}

.item-blue {
    text-align: center;
    border: #59B2EF solid 4px;
    border-radius: 20px;
}

.result-container2 h2 {
    margin-bottom: 200px;
}

.result-container2 p {
    font-weight: bold !important;
}

.result-container2 img {
    width: 100px;
}

.each-why-title {
    position: absolute;
    width: 170px;
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone 11pro + iphone 12 size >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 320px) and (max-width: 374px) {
    .result-container2 {
        margin: 8vh 0.5vh;
    }
    .result-container2 h1 {
        text-align: center;
        margin-bottom: 60px;
    }
    .item {
        width: 315px;
        height: 140px;
        margin: 70px auto;
        text-align: center;
        position: relative;
    }
    .each-why-title {
        position: absolute;
        top: -50px;
        left: 78px;
        width: 170px;
    }
    .result-container2 img {
        width: 100%;
    }
    .result-container2 h3 {
        color: white;
        position: absolute;
        top: -5px;
        left: 55px;
    }
    .result-container2 p {
        margin-top: 60px;
        padding: 0 5px;
        font-size: 16px !important;
    }
    .flow-1 {
        display: block;
    }
    .flow-2 {
        display: none;
    }
}

@media (min-width: 375px) and (max-width: 413px) {
    .result-container2 {
        margin: 8vh 0.5vh;
    }
    .result-container2 h1 {
        text-align: center;
        margin-bottom: 60px;
    }
    .item {
        width: 340px;
        height: 140px;
        margin: 70px auto;
        text-align: center;
        position: relative;
    }
    .each-why-title {
        position: absolute;
        top: -50px;
        left: 78px;
        width: 170px;
    }
    .result-container2 img {
        width: 100%;
    }
    .result-container2 h3 {
        color: white;
        position: absolute;
        top: -5px;
        left: 55px;
    }
    .result-container2 p {
        margin-top: 60px;
        padding: 0 5px;
        font-size: 16px !important;
    }
    .flow-1 {
        display: block;
    }
    .flow-2 {
        display: none;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone 11 size >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 414px) and (max-width: 428px) {
    .result-container2 {
        margin: 8vh 0.5vh;
    }
    .result-container2 h1 {
        text-align: center;
        margin-bottom: 60px;
    }
    .item {
        width: 360px;
        height: 140px;
        margin: 70px auto;
        text-align: center;
        position: relative;
    }
    .each-why-title {
        position: absolute;
        top: -50px;
        left: 78px;
        width: 170px;
    }
    .result-container2 img {
        width: 100%;
    }
    .result-container2 h3 {
        color: white;
        position: absolute;
        top: -5px;
        left: 60px;
    }
    .result-container2 p {
        margin-top: 60px;
        padding: 0 5px;
        font-size: 16px !important;
    }
    .flow-1 {
        display: block;
    }
    .flow-2 {
        display: none;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone pro max >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 429px) and (max-width: 450px) {
    .result-container2 {
        margin: 8vh 0.5vh;
    }
    .result-container2 h1 {
        text-align: center;
        margin-bottom: 60px;
    }
    .item {
        width: 380px;
        height: 160px;
        margin: 70px auto;
        text-align: center;
        position: relative;
    }
    .each-why-title {
        position: absolute;
        top: -50px;
        left: 95px;
        width: 170px;
    }
    .result-container2 img {
        width: 100%;
    }
    .result-container2 h3 {
        color: white;
        position: absolute;
        top: -5px;
        left: 60px;
    }
    .result-container2 p {
        margin-top: 60px;
        padding: 0 5px;
        font-size: 18px !important;
    }
    .flow-1 {
        display: block;
    }
    .flow-2 {
        display: none;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< other bigger than iphone pro >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 451px) and (max-width: 599px) {
    .result-container2 {
        margin: 50px 0.5vh 100px 0.5vh;
    }
    .result-container2 h1 {
        text-align: center;
        margin-bottom: 80px;
    }
    .item {
        width: 400px;
        height: 140px;
        margin: 70px auto;
        text-align: center;
        position: relative;
    }
    .each-why-title {
        position: absolute;
        top: -60px;
        left: 90px;
        width: 200px;
    }
    .result-container2 img {
        width: 100%;
    }
    .result-container2 h3 {
        color: white;
        position: absolute;
        top: 0px;
        left: 70px;
    }
    .result-container2 p {
        margin-top: 55px;
        padding: 0 10px;
        font-size: 18px !important;
    }
    .flow-1 {
        display: block;
    }
    .flow-2 {
        display: none;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .result-container2 {
        margin: 60px 0.5vh;
    }
    .result-container2 h1 {
        text-align: center;
        margin-bottom: 100px;
    }
    .item {
        width: 450px;
        height: 150px;
        margin: 80px auto;
        text-align: center;
        position: relative;
    }
    .each-why-title {
        position: absolute;
        top: -60px;
        left: 120px;
        width: 200px;
    }
    .result-container2 img {
        width: 100%;
    }
    .result-container2 h3 {
        color: white;
        position: absolute;
        top: 0px;
        left: 70px;
    }
    .result-container2 p {
        margin-top: 60px;
        padding: 0 15px;
        font-size: 20px !important;
    }
    .flow-1 {
        display: block;
    }
    .flow-2 {
        display: none;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< ipad min >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 700px) and (max-width: 959px) {
    .result-container2 {
        margin: 100px 0.5vh;
    }
    .result-container2 h1 {
        text-align: center;
        margin-bottom: 100px;
    }
    .item {
        width: 450px;
        height: 150px;
        margin: 80px auto;
        text-align: center;
        position: relative;
    }
    .each-why-title {
        position: absolute;
        top: -60px;
        left: 120px;
        width: 200px;
    }
    .result-container2 img {
        width: 100%;
    }
    .result-container2 h3 {
        color: white;
        position: absolute;
        top: 0px;
        left: 70px;
    }
    .result-container2 p {
        margin-top: 58px;
        padding: 0 15px;
        font-size: 20px !important;
    }
    .flow-1 {
        display: block;
    }
    .flow-2 {
        display: none;
    }
}

@media (min-width: 960px) and (max-width: 1023px) {
    .result-container2 {
        margin: 100px 0.5vh;
    }
    .result-container2 h1 {
        text-align: center;
        margin-bottom: 90px;
    }
    .item {
        width: 450px;
        height: 150px;
        margin: 80px auto;
        text-align: center;
        position: relative;
    }
    .each-why-title {
        position: absolute;
        top: -60px;
        left: 120px;
        width: 200px;
    }
    .result-container2 img {
        width: 100%;
    }
    .result-container2 h3 {
        color: white;
        position: absolute;
        top: 0px;
        left: 70px;
    }
    .result-container2 p {
        margin-top: 58px;
        padding: 0 15px;
        font-size: 20px !important;
    }
    .flow-1 {
        display: block;
    }
    .flow-2 {
        display: none;
    }
}

/* iPad Pro 12.9" */

@media (min-width: 1024px) {
    .result-container2 {
        margin: 60px 0.5vh 100px 0.5vh;
    }
    .result-container2 h1 {
        text-align: center;
        margin-bottom: 90px;
    }
    .item {
        width: 500px;
        height: 150px;
        margin: 80px auto;
        text-align: center;
        position: relative;
    }
    .each-why-title {
        position: absolute;
        top: -60px;
        left: 130px;
        width: 220px;
    }
    .result-container2 img {
        width: 100%;
    }
    .result-container2 h3 {
        color: white;
        position: absolute;
        top: 0px;
        left: 70px;
    }
    .result-container2 p {
        margin-top: 58px;
        padding: 0 15px;
        font-size: 20px !important;
    }
    .flow-1 {
        display: block;
    }
    .flow-2 {
        display: none;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< long width monitor  >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 1280px) {
    .result-container2 {
        margin: 80px 0.5vh 150px 0.5vh;
    }
    .result-container2 h1 {
        text-align: center;
        margin-bottom: 90px;
    }
    .item {
        width: 600px;
        height: 180px;
        margin: 100px auto;
        text-align: center;
        position: relative;
    }
    .each-why-title {
        position: absolute;
        top: -60px;
        left: 180px;
        width: 220px;
    }
    .result-container2 img {
        width: 100%;
    }
    .result-container2 h3 {
        color: white;
        position: absolute;
        top: 0px;
        left: 70px;
    }
    .result-container2 p {
        margin-top: 80px;
        padding: 0 15px;
        font-size: 20px !important;
    }
    .flow-1 {
        display: block;
    }
    .flow-2 {
        display: none;
    }
}