
.allcourses-container {
    background-color: rgb(255, 250, 240);
    width: 100%;
}

.allcourses-container h1{
   text-align: center;
}

.allcourses-container p{
    font-weight: 700;
}

.allcourses-container .MuiGrid-spacing-xs-2>.MuiGrid-item {
    position: relative;
}

.allcourses-container .card-title-1 {
    color: white;
    position: absolute;
    left: 1vh;
}

.allcourses-container .card-title-2 {
    color: white;
    position: absolute;
    left: 1vh;
}

.allcourses-container .courses-btn button {
    background-color: rgb(236, 236, 236) !important;
    color: rgb(48, 48, 48);
}

.allcourses-container .MuiPaper-elevation1{
    margin: 0 auto !important;
}

/* make card image darken effect */

.cardmedia-wrap {
    background-color: black;
    overflow: hidden;
}

.cardmedia-wrap-2 {
    background-color: white;
    overflow: hidden;
}

/* card image */

.allcourses-container .MuiCardMedia-root {
    opacity: 0.7;
}

.allcourses-container .makeStyles-root-1 {
    max-width: 100%;
}

/* button */

.card-tag span {
    background-color: #FBBC05 !important;
    color: white !important;
    margin-right: 10px;
    padding: 7px;
}

.card-tag2 span {
    background-color: #dd4040 !important;
    color: white !important;
    margin-right: 10px;
    padding: 7px;
}

.card-tag, .card-tag2 {
    margin-bottom: 2vh;
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone 11pro , iphone 12  >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 320px) and (max-width: 349px) {
    .allcourses-container {
        margin-top: 20px auto;
        padding: 30px;
    }
    .allcourses-container .card-title-1 {
        top: 145px;
        font-size: 24px !important;
    }
    .allcourses-container .card-title-2 {
        top: 180px;
        font-size: 21px !important;
    }
    .allcourses-container .cardmedia-wrap {
        height: 220px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 220px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 250px !important;
    }
    .allcourses-container .courses-btn button {
        width: 120px;
        height: 36px !important;
    }
    .allcourses-container .courses-btn span {
        font-size: 17px !important;
    }
}

@media (min-width: 350px) and (max-width: 374px) {
    .allcourses-container {
        margin-top: 20px auto;
        padding: 30px;
    }
    .allcourses-container .card-title-1 {
        top: 145px;
        font-size: 24px !important;
    }
    .allcourses-container .card-title-2 {
        top: 180px;
        font-size: 21px !important;
    }
    .allcourses-container .cardmedia-wrap {
        height: 220px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 220px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 220px !important;
    }
    .allcourses-container .courses-btn button {
        width: 120px;
        height: 36px !important;
    }
    .allcourses-container .courses-btn span {
        font-size: 17px !important;
    }
}

@media (min-width: 375px) and (max-width: 413px) {
    .allcourses-container {
        margin-top: 8vh auto;
        padding: 30px;
    }
    .allcourses-container .card-title-1 {
        top: 145px;
        font-size: 24px !important;
    }
    .allcourses-container .card-title-2 {
        top: 180px;
        font-size: 21px !important;
    }
    .allcourses-container .cardmedia-wrap {
        height: 220px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 220px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 220px !important;
    }
    .allcourses-container .courses-btn button {
        width: 120px;
        height: 36px !important;
    }
    .allcourses-container .courses-btn span {
        font-size: 17px !important;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone 11 size >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 414px) and (max-width: 428px) {
    .allcourses-container {
        margin-top: 8vh auto;
        padding: 30px;
    }
    .allcourses-container .courses-btn button {
        width: 130px;
        height: 40px !important;
    }
    .allcourses-container .courses-btn span {
        font-size: 17.5px !important;
    }
    .allcourses-container .card-title-1 {
        top: 160px;
        font-size: 25px !important;
    }
    .allcourses-container .card-title-2 {
        top: 195px;
        font-size: 22px !important;
    }
    .allcourses-container .cardmedia-wrap {
        height: 240px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 240px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 190px !important;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone pro max >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 429px) and (max-width: 450px) {
    .allcourses-container {
        margin-top: 8vh auto;
        padding: 40px;
    }
    .allcourses-container .courses-btn button {
        width: 130px;
        height: 40px !important;
    }
    .allcourses-container .card-title-1 {
        top: 160px;
        font-size: 27px !important;
    }
    .allcourses-container .card-title-2 {
        top: 200px;
        font-size: 23px !important;
    }
    .allcourses-container .cardmedia-wrap {
        height: 250px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 250px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 220px !important;
    }
    .allcourses-container .makeStyles-root-1{
        width: 90% !important;
        margin: 0 auto;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< other bigger than iphone pro >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 451px) and (max-width: 599px) {
    .allcourses-container {
        margin-top: 8vh auto;
        padding: 40px;
    }
    .allcourses-container .courses-btn button {
        width: 130px;
        height: 40px !important;
    }
    .allcourses-container .card-title-1 {
        top: 155px;
        font-size: 29px !important;
    }
    .allcourses-container .card-title-2 {
        top: 195px;
        font-size: 25px !important;
    }
    .allcourses-container .cardmedia-wrap {
        height: 240px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 250px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 200px !important;
    }
    .allcourses-container .makeStyles-root-1{
        width: 90% !important;
        margin: 0 auto;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .allcourses-container {
        padding: 0 30px;
    }
    .allcourses-container .courses-btn button {
        width: 110px;
        height: 35px !important;
    }
    .allcourses-container .card-title-1 {
        top: 130px;
        font-size: 21px !important;
    }
    .allcourses-container .card-title-2 {
        top: 160px;
        font-size: 18px !important;
    }
    .allcourses-container .cardmedia-wrap {
        height: 200px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 200px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 220px!important;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< ipad mini  >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 700px) and (max-width: 799px) {
    .allcourses-container {
        padding: 0 30px;
    }
    .allcourses-container .courses-btn button {
        width: 120px;
        height: 40px !important;
    }
    .allcourses-container .card-title-1 {
        top: 145px;
        font-size: 25px !important;
    }
    .allcourses-container .card-title-2 {
        top: 180px;
        font-size: 21px !important;
    }
    .allcourses-container .cardmedia-wrap {
        height: 230px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 230px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 190px!important;
    }
}

@media (min-width: 800px) and (max-width: 959px) {
    .allcourses-container {
        padding: 0 30px;
        margin-top: 100px ;
    }
    .allcourses-container .courses-btn button {
        width: 120px;
        height: 40px !important;
    }
    .allcourses-container .card-title-1 {
        top: 145px;
        font-size: 25px !important;
    }
    .allcourses-container .card-title-2 {
        top: 180px;
        font-size: 21px !important;
    }
    .allcourses-container .cardmedia-wrap {
        height: 230px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 230px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 190px!important;
    }
    .allcourses-container .makeStyles-root-1{
        width: 90% !important;
        margin: 0 auto;
    }
}

@media (min-width: 960px) and (max-width: 999px) {
    .allcourses-container{
        width: 930px;
        margin: 0 auto;
    }
    .allcourses-container .courses-btn button {
        width: 120px;
        height: 40px!important;
    }
    .allcourses-container .card-title-1 {
        top: 140px;
        font-size: 24px !important;
    }
    .allcourses-container .card-title-2 {
        top: 175px;
        font-size: 20px !important;
    }
    .allcourses-container .cardmedia-wrap {
        height: 220px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 220px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 215px !important;
    }
    .allcourses-container .makeStyles-root-1{
        width: 90% !important;
        margin: 0 auto;
    }
}

@media (min-width: 1000px) and (max-width: 1024px) {
    .allcourses-container {
        padding: 0 40px;
        margin-top: 120px;
    }
    .allcourses-container .courses-btn button {
        width: 120px;
        height: 40px!important;
    }
    .allcourses-container .card-title-1 {
        top: 140px;
        font-size: 24px !important;
    }
    .allcourses-container .card-title-2 {
        top: 175px;
        font-size: 20px !important;
    }
    .allcourses-container .cardmedia-wrap {
        height: 220px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 220px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 280px !important;
    }
    
}

/* <<<<<<<<<<<<<<<<<<<<<< long width monitor  >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 1025px) {
    .allcourses-container {
        padding: 0 30px;
        margin: 140px auto 0 auto;
        width: 980px;
    }
    .allcourses-container p{
        font-size: 15px !important;
    }
    .allcourses-container .courses-btn button {
        width: 120px;
        height: 40px !important;
    }
    .allcourses-container .courses-btn span {
        font-size: 18px !important;
    }
    .allcourses-container .card-title-1 {
        top: 140px;
        font-size: 26px !important;
    }
    .allcourses-container .card-title-2 {
        top: 175px;
        font-size: 22px !important;
    }
    .card-tag, .card-tag2 {
        margin-bottom: 20px;
    }
    .allcourses-container .cardmedia-wrap {
        height: 230px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 230px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 220px !important;
    }
}

@media (min-width: 1100px) {
    .allcourses-container {
        padding: 0 30px;
        width: 1000px;
    }
    .allcourses-container .courses-btn button {
        width: 120px;
        height: 40px !important;
    }
    .allcourses-container .courses-btn span {
        font-size: 18px !important;
    }
    .allcourses-container .card-title-1 {
        top: 140px;
        font-size: 26px !important;
    }
    .allcourses-container .card-title-2 {
        top: 175px;
        font-size: 22px !important;
    }
    .card-tag, .card-tag2 {
        margin-bottom: 20px;
    }
    .allcourses-container .cardmedia-wrap {
        height: 230px;
    }
    .allcourses-container .MuiCardMedia-root {
        height: 230px !important;
    }
    .allcourses-container .MuiCardContent-root {
        height: 220px !important;
    }
    .allcourses-container .makeStyles-root-1 {
        width: 360px;
        margin: 0 auto;
    }
}