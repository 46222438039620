.feedback-container {
    padding: 0 5vh;
    text-align: center;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.feedback-container .carousel {
    width: 100%;
    height: 45vh;
}

.feedback-container .carousel-indicators li {
    background-color: transparent !important;
}

/* single feedback style */

.each-feedback-container {
    /* background-color: pink; */
    width: 80%;
    margin: 0 auto;
    padding: 1.5vh;
}

/* parents icon */

.each-feedback-container img {
    margin: 10px;
    text-align: center;
    border-radius: 50%;
    background-color: rgb(236, 236, 236);
}

/* single feedback style */

.each-feedback-text {
    background-color: #FFDC48 !important;
    background-size: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 5vh;
}

.feedback-container .carousel-control-next-icon, .feedback-container .carousel-control-prev-icon {
    opacity: 1 !important;
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone 12 size >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 320px) and (max-width: 374px) {
    .feedback-container {
        margin: 50px 5px;
        padding: 0;
    }
    .feedback-container .carousel {
        height: 480px;
    }
    .each-feedback-container {
        width: 100% !important;
    }
    .each-feedback-text {
        padding: 28px;
    }
    .feedback-container .carousel-control-next-icon, .feedback-container .carousel-control-prev-icon {
        height: 40px !important;
        width: 20px !important;
    }
}

@media (min-width: 375px) and (max-width: 414px) {
    .feedback-container {
        margin: 50px 5px;
        padding: 0;
    }
    .feedback-container .carousel {
        height: 430px;
    }
    .each-feedback-container {
        width: 100% !important;
    }
    .each-feedback-text {
        padding: 28px;
    }
    .feedback-container .carousel-control-next-icon, .feedback-container .carousel-control-prev-icon {
        height: 40px !important;
        width: 30px !important;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone 12 size >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 414px) and (max-width: 427px) {
    .feedback-container {
        margin: 50px 5px;
        padding: 0;
    }
    .feedback-container .carousel {
        height: 430px;
    }
    /* single feedback style */
    .each-feedback-container {
        width: 100% !important;
    }
    /* single feedback style */
    .each-feedback-text {
        padding: 28px;
    }
    .feedback-container .carousel-control-next-icon, .feedback-container .carousel-control-prev-icon {
        height: 40px !important;
        width: 30px !important;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone pro max >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 428px) and (max-width: 450px) {
    .feedback-container {
        margin: 80px 5px;
        padding: 0;
    }
    .feedback-container .carousel {
        height: 450px;
    }
    .each-feedback-container {
        width: 100%;
    }
    .each-feedback-text {
        padding: auto 25px;
        height: 250px;
    }
    .feedback-container .carousel-control-next-icon, .feedback-container .carousel-control-prev-icon {
        height: 40px !important;
        width: 30px !important;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< other bigger than iphone pro >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 451px) and (max-width: 599px) {
    .feedback-container {
        margin: 50px 5px;
        padding: 0;
    }
    .feedback-container .carousel {
        height: 400px;
    }
    .each-feedback-container {
        width: 100%;
    }
    .each-feedback-text {
        padding: 30px 50px;
    }
    .feedback-container .carousel-control-next-icon, .feedback-container .carousel-control-prev-icon {
        height: 40px !important;
        width: 30px !important;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .feedback-container {
        margin: 80px 5px;
        padding: 0;
    }
    .feedback-container .carousel {
        height: 430px;
    }
    .each-feedback-container {
        width: 100%;
    }
    .each-feedback-text {
        padding: 30px 90px;
        height: 230px;
    }
    .feedback-container .carousel-control-next-icon, .feedback-container .carousel-control-prev-icon {
        height: 40px !important;
        width: 30px !important;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< ipad  >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 700px) and (max-width: 1023px) {
    .feedback-container {
        margin: 150px 10px;
    }
    .feedback-container .carousel {
        height: 420px;
    }
    .each-feedback-container {
        width: 100%;
    }
    .each-feedback-text {
        height: 250px;
        padding: 30px 90px;
    }
    .feedback-container .carousel-control-next-icon, .feedback-container .carousel-control-prev-icon {
        height: 50px !important;
        width: 40px !important;
    }
}

@media (min-width: 1025px) {
    .feedback-container .carousel {
        height: 650px;
        width: 900px;
        margin: 0 auto;
    }
    .each-feedback-container {
        width: 900px;
        padding: 4vh !important;
    }
    .feedback-container {
        margin-top: 150px;
    }
    .each-feedback-text {
        height: 300px;
        padding: 50px 120px;
    }
    .feedback-container .carousel-control-next-icon, .feedback-container .carousel-control-prev-icon {
        height: 60px !important;
        width: 50px !important;
    }
}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}

@media (min-width: 1600px) {}