.python-game-container {
    margin-top: 120px ;
    margin-bottom: 150px !important;
}

.python-game-photo-container {
    width: 100%;
    overflow: hidden;
}

.python-game-photo-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.python-game-photo-container {
    width: 100%;
}

.python-game-photo-container .carousel-control-next-icon, .python-game-photo-container .carousel-control-prev-icon {
    display: none !important;
}

@media (min-width: 320px) and (max-width: 413px) {
    .python-game-container {
        margin-top: 100px !important;
    }
    .python-game-photo-container .carousel {
        height: 190px;
    }
    .python-game-photo-container {
        height: 200px;
    }
    .pythongame-container p {
        margin: 20px 10px;
    }
    .pythongame-container {
        margin: 50px auto;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .python-game-container {
        margin-top: 100px !important;
    }
    .python-game-photo-container .carousel {
        height: 240px;
    }
    .python-game-photo-container {
        height: 250px;
    }
    .pythongame-container p {
        margin: 20px 10px;
    }
    .pythongame-container {
        margin: 50px auto;
    }
}

@media (min-width: 429px) and (max-width: 450px) {
    .python-game-container {
        margin-top: 100px !important;
    }
    .python-game-photo-container .carousel {
        height: 250px;
    }
    .python-game-photo-container {
        height: 250px;
    }
    .pythongame-container p {
        margin: 20px 10px;
    }
    .pythongame-container {
        margin: 60px auto;
    }
}

@media (min-width: 451px) and (max-width: 599px) {
    .python-game-container {
        margin-top: 100px !important;
    }
    .python-game-photo-container .carousel {
        height: 260px;
    }
    .python-game-photo-container {
        height: 300px;
    }
    .pythongame-container p {
        margin: 20px 20px;
    }
    .pythongame-container {
        margin: 60px auto;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .python-game-container {
        margin-top: 100px !important;
    }
    .python-game-photo-container .carousel {
        height: 340px;
    }
    .python-game-photo-container {
        height: 350px;
    }
    .pythongame-container p {
        margin: 20px 30px;
    }
    .pythongame-container {
        margin: 60px auto;
    }
}

@media (min-width: 700px) and (max-width: 959px) {
    .python-game-container {
        margin-top: 100px !important;
    }
    .python-game-photo-container .carousel {
        height: 400px !important;
    }
    .python-game-photo-container {
        height: 400px;
    }
    .pythongame-container p {
        margin: 20px 30px;
    }
    .pythongame-container {
        margin: 60px auto;
    }
}

@media (min-width: 960px) and (max-width: 1024px) {
    .python-game-container {
        margin-top: 100px !important;
    }
    .python-game-photo-container {
        height: 480px;
    }
    .python-game-photo-container .carousel {
        height: 480px;
    }
    .pythongame-container p {
        margin: 20px 50px;
    }
    .pythongame-container {
        margin: 60px auto;
        width: 80%;
    }
}

@media (min-width: 1025px) {
    .python-game-photo-container {
        height: 500px;
    }
    .python-game-photo-container .carousel {
        height: 490px;
    }
    .pythongame-container {
        margin: 60px auto;
        width: 900px;
    }
}

@media (min-width: 1200px) {
    .python-game-photo-container {
        height: 550px;
    }
    .python-game-photo-container .carousel {
        height: 540px;
    }
    .pythongame-container {
        margin: 60px auto;
        width: 1000px;
    }
}

@media (min-width: 1400px) {
    .python-game-photo-container {
        height: 550px;
    }
    .python-game-photo-container .carousel {
        height: 540px;
    }
}

@media (min-width: 1600px) {
    .python-game-photo-container {
        height: 600px;
    }
    .python-game-photo-container .carousel {
        height: 590px;
    }
}