.tab2-container {
    overflow: hidden;
}

.tab2-container .nav-link {
    text-align: center;
}

.tab2-container .carousel img {
    width: 100%;
}

.tab2-container .nav-link {
    border: #1665A8 solid 1px;
    border-radius: 2px;
}

.tab2-container .nav-link:hover {
    background-color: #1665A8;
    color: white !important;
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone 11pro + iphone 12 size >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 320px) and (max-width: 413px) {
    .tab2-container {
        margin: 60px auto;
    }
    .tab2-container h1 {
        margin-bottom: 30px;
    }
    .tab2-container .nav-link {
        padding: 0px;
        margin: 0px 3px !important;
        width: 76px !important;
        font-size: 14px;
    }
    .tab2-container .carousel {
        margin: 20px auto;
        width: 100%;
    }
    .tab2-container .tab-row {
        width: 170px !important;
        margin: 0 auto;
    }
    .tab2-container .tab-row .row{
        width: 180px !important;
        margin: 0 auto;
    }
    .tab2-container .carousel-control-next-icon, .tab2-container .carousel-control-prev-icon {
        height: 40px;
        width: 30px;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone 11 size >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 414px) and (max-width: 428px) {
    .tab2-container {
        margin: 80px auto;
    }
    .tab2-container h1 {
        margin-bottom: 30px;
    }
    .tab2-container .nav-link {
        padding: 0px;
        margin: 0px 3px !important;
        width: 76px !important;
        font-size: 14px;
    }
    .tab2-container .carousel {
        margin: 20px auto;
        width: 100%;
    }
    .tab2-container .tab-row {
        width: 170px !important;
        margin: 0 auto;
    }
    .tab2-container .tab-row .row{
        width: 180px !important;
        margin: 0 auto;
    }
    .tab2-container .carousel-control-next-icon, .tab2-container .carousel-control-prev-icon {
        height: 40px;
        width: 30px;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone pro max >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 429px) and (max-width: 450px) {
    .tab2-container {
        margin: 80px auto;
    }
    .tab2-container h1 {
        margin-bottom: 30px;
    }
    .tab2-container .nav-link {
        padding: 0px;
        margin: 0px 3px !important;
        width: 76px !important;
        font-size: 14px;
    }
    .tab2-container .carousel {
        margin: 20px auto;
        width: 100%;
    }
    .tab2-container .tab-row {
        width: 170px !important;
        margin: 0 auto;
    }
    .tab2-container .tab-row .row{
        width: 180px !important;
        margin: 0 auto;
    }
    .tab2-container .carousel-control-next-icon, .tab2-container .carousel-control-prev-icon {
        height: 40px;
        width: 30px;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< other bigger than iphone pro >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 451px) and (max-width: 599px) {
    .tab2-container {
        margin: 80px auto;
    }
    .tab2-container h1 {
        margin-bottom: 30px;
    }
    .tab2-container .nav-link {
        padding: 0px;
        margin: 0px 3px !important;
        width: 80px !important;
        font-size: 14px;
    }
    .tab2-container .carousel {
        margin: 20px auto;
        width: 100%;
    }
    .tab2-container .tab-row {
        width: 170px !important;
        margin: 0 auto;
    }
    .tab2-container .tab-row .row{
        width: 180px !important;
        margin: 0 auto;
    }
    .tab2-container .carousel-control-next-icon, .tab2-container .carousel-control-prev-icon {
        height: 45px;
        width: 35px;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .tab2-container {
        margin: 80px auto;
    }
    .tab2-container h1 {
        margin-bottom: 30px;
    }
    .tab2-container .nav-link {
        padding: 0px;
        margin: 0px 3px !important;
        width: 84px !important;
        font-size: 14px;
    }
    .tab2-container .carousel {
        margin: 20px auto;
        width: 100%;
    }
    .tab2-container .tab-row {
        width: 170px !important;
        margin: 0 auto;
    }
    .tab2-container .tab-row .row{
        width: 180px !important;
        margin: 0 auto;
    }
    .tab2-container .carousel-control-next-icon, .tab2-container .carousel-control-prev-icon {
        height: 50px;
        width: 40px;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< ipad min >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 700px) and (max-width: 959px) {
    .tab2-container {
        margin: 80px auto;
    }
    .tab2-container h1 {
        margin-bottom: 30px;
    }
    .tab2-container .nav-link {
        padding: 0px;
        margin: 0px 3px !important;
        width: 85px !important;
        font-size: 14px;
    }
    .tab2-container .carousel {
        margin: 20px auto;
        width: 100%;
    }
    .tab2-container .tab-row {
        width: 180px !important;
        margin: 0 auto;
    }
    .tab2-container .tab-row .row{
        width: 190px !important;
        margin: 0 auto;
    }
    .tab2-container .carousel-control-next-icon, .tab2-container .carousel-control-prev-icon {
        height: 50px;
        width: 40px;
    }
}

@media (min-width: 960px) and (max-width: 1023px) {
    .tab2-container {
        margin: 80px auto;
    }
    .tab2-container h1 {
        margin-bottom: 30px;
    }
    .tab2-container .nav-link {
        padding: 3px;
        margin: 0px 3px !important;
        width: 90px !important;
        height: 50px;
        font-size: 16px;
    }
    .tab2-container .carousel {
        margin: 20px auto;
        width: 100%;
    }
    .tab2-container .tab-row {
        width: 190px !important;
        margin: 0 auto;
    }
    .tab2-container .tab-row .row{
        width: 200px !important;
        margin: 0 auto;
    }
    .tab2-container .carousel-control-next-icon, .tab2-container .carousel-control-prev-icon {
        height: 55px;
        width: 45px;
    }
}

@media (min-width: 1024px) {
    .tab2-container {
        margin: 80px auto;
    }
    .tab2-container h1 {
        margin-bottom: 30px;
    }
    .tab2-container .nav-link {
        padding: 3px;
        margin: 0px 3px !important;
        width: 110px !important;
        height: 50px;
        font-size: 18px;
    }
    .tab2-container .carousel {
        margin: 20px auto;
        width: 100%;
    }
    .tab2-container .tab-row {
        width: 230px !important;
        margin: 0 auto;
    }
    .tab2-container .tab-row .row{
        width: 240px !important;
        margin: 0 auto;
    }
    .tab2-container .carousel-control-next-icon, .tab2-container .carousel-control-prev-icon {
        height: 55px;
        width: 45px;
    }
}

@media (min-width: 1025px) {
    .tab2-container .carousel {
        margin: 50px auto;
        width: 1000px;
    }
}

