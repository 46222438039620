.student-project2-container {
    margin: 0 auto !important;
    width: 90%;
    margin-top: 150px !important;
    padding-bottom: 150px !important;
}

.student-project2-container .click {
    font-weight: 400;
}

.singel-student-project2 {
    margin: 0 auto;
}

.student-project2-container p {
    margin-bottom: 0px !important;
}

.student-project2-container .sp2-name {
    font-weight: 500;
}

.student-project2-container .sp2-played {
    font-weight: 500;
}

.student-project2-frame {
    vertical-align: center;
    overflow: hidden;
}

.student-project2-frame img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.result-btn {
    margin: 0 auto;
}

.MuiAppBar-colorPrimary {
    height: 50px;
    background-color: #FBBC05 !important;
}

iframe {
    border: none !important;
}

.iframe-wrapper {
    min-width: 320px;
    min-height: 180px;
    overflow: hidden;
}

.iframe-wrapper-small {
    min-width: 320px;
    min-height: 170px;
    overflow: hidden;
}

a {
    cursor: pointer !important;
}

@media (min-width: 320px) and (max-width: 374px) {
    .student-project2-container .sp2-grid {
        margin-top: 30px;
    }
    .singel-student-project2 {
        width: 150px;
    }
    .student-project2-frame {
        height: 90px;
        width: 135px;
        margin-bottom: 5px;
    }
    .student-project2-container .sp2-title {
        font-weight: bold;
        font-size: 15px !important;
    }
    .student-project2-container .sp2-name {
        font-size: 14px !important;
    }
    .student-project2-container .sp2-played {
        font-size: 12px !important;
    }
    .student-project2-container button {
        width: 150px;
        height: 35px;
        margin-top: 50px;
    }
    .student-project2-container .note {
        font-size: 14px !important;
        margin-top: 10px;
    }
    .student-project2-container .result-btn {
        width: 150px;
    }
    .iframe-wrapper {
        width: 100%;
        height: 180px;
        overflow: hidden;
    }
    .iframe-wrapper-small {
        width: 100%;
        height: 170px;
        overflow: hidden;
    }
    .MuiDialog-root iframe {
        width: 100% !important;
        height: 290%;
        margin-top: -57px;
    }
    .sp-dialog {
        height: 500px !important;
    }
    .game-close-button {
        margin-left: 95% !important;
        margin-top: -20px !important;
    }
    .game-title {
        font-size: 20px !important;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 5px !important;
    }
    .game-name {
        font-size: 15px !important;
        margin-left: 20px;
        font-weight: 500 !important;
    }
    .game-played {
        font-size: 12px !important;
        font-weight: 500 !important;
    }
    .game-des {
        font-size: 12px !important;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px !important;
        font-weight: 500 !important;
    }
    .game-des p {
        margin-bottom: 10px;
    }
}

@media (min-width: 375px) and (max-width: 413px) {
    .student-project2-container .sp2-grid {
        margin-top: 30px;
    }
    .singel-student-project2 {
        width: 165px;
    }
    .student-project2-frame {
        height: 110px;
        width: 165px;
        margin-bottom: 5px;
    }
    .student-project2-container .sp2-title {
        font-weight: bold;
        font-size: 15px !important;
    }
    .student-project2-container .sp2-name {
        font-size: 14px !important;
    }
    .student-project2-container .sp2-played {
        font-size: 12px !important;
    }
    .student-project2-container button {
        width: 150px;
        height: 35px;
        margin-top: 50px;
    }
    .student-project2-container .note {
        font-size: 14px !important;
        margin-top: 10px;
    }
    .student-project2-container .result-btn {
        width: 150px;
    }
    .iframe-wrapper {
        width: 100%;
        height: 210px;
        overflow: hidden;
    }
    .iframe-wrapper-small {
        width: 100%;
        height: 200px;
        overflow: hidden;
    }
    .MuiDialog-root iframe {
        width: 100% !important;
        height: 290%;
        margin-top: -56px;
    }
    .sp-dialog {
        height: 500px !important;
    }
    .game-close-button {
        margin-left: 95% !important;
        margin-top: -20px !important;
    }
    .game-title {
        font-size: 22px !important;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 5px !important;
    }
    .game-name {
        font-size: 18px !important;
        margin-left: 20px;
        font-weight: 500 !important;
    }
    .game-played {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
    .game-des {
        font-size: 14px !important;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px !important;
        font-weight: 500 !important;
    }
    .game-des p {
        margin-bottom: 10px;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .student-project2-container .sp2-grid {
        margin-top: 30px;
    }
    .singel-student-project2 {
        width: 175px;
    }
    .student-project2-frame {
        height: 110px;
        width: 175px;
        margin-bottom: 5px;
    }
    .student-project2-container .sp2-title {
        font-weight: bold;
        font-size: 16px !important;
    }
    .student-project2-container .sp2-name {
        font-size: 16px !important;
    }
    .student-project2-container .sp2-played {
        font-size: 13px !important;
    }
    .student-project2-container button {
        width: 150px;
        height: 35px;
        margin-top: 50px;
    }
    .student-project2-container .note {
        font-size: 14px !important;
        margin-top: 10px;
    }
    .student-project2-container .result-btn {
        width: 150px;
    }
    .iframe-wrapper {
        width: 100%;
        height: 227px;
        overflow: hidden;
    }
    .iframe-wrapper-small {
        width: 100%;
        height: 225px;
        overflow: hidden;
    }
    .MuiDialog-root iframe {
        width: 100% !important;
        height: 290%;
        margin-top: -56px;
    }
    .sp-dialog {
        height: 500px !important;
    }
    .game-close-button {
        margin-left: 95% !important;
        margin-top: -20px !important;
    }
    .game-title {
        font-size: 22px !important;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 5px !important;
    }
    .game-name {
        font-size: 18px !important;
        margin-left: 20px;
        font-weight: 500 !important;
    }
    .game-played {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
    .game-des {
        font-size: 14px !important;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px !important;
        font-weight: 500 !important;
    }
    .game-des p {
        margin-bottom: 10px;
    }
}

@media (min-width: 429px) and (max-width: 450px) {
    .student-project2-container .sp2-grid {
        margin-top: 30px;
    }
    .singel-student-project2 {
        width: 185px;
    }
    .student-project2-frame {
        height: 115px;
        width: 185px;
        margin-bottom: 5px;
    }
    .student-project2-container .sp2-title {
        font-weight: bold;
        font-size: 17px !important;
    }
    .student-project2-container .sp2-name {
        font-size: 16px !important;
    }
    .student-project2-container .sp2-played {
        font-size: 13px !important;
    }
    .student-project2-container button {
        width: 150px;
        height: 35px;
        margin-top: 60px;
    }
    .student-project2-container .note {
        font-size: 14px !important;
        margin-top: 10px;
    }
    .student-project2-container .result-btn {
        width: 150px;
    }
    .iframe-wrapper {
        width: 100%;
        height: 244px;
        overflow: hidden;
    }
    .iframe-wrapper-small {
        width: 100%;
        height: 230px;
        overflow: hidden;
    }
    .MuiDialog-root iframe {
        width: 100% !important;
        height: 290%;
        margin-top: -56px;
    }
    .sp-dialog {
        height: 500px !important;
    }
    .game-close-button {
        margin-left: 95% !important;
        margin-top: -20px !important;
    }
    .game-title {
        font-size: 23px !important;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 5px !important;
    }
    .game-name {
        font-size: 18px !important;
        margin-left: 20px;
        font-weight: 500 !important;
    }
    .game-played {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
    .game-des {
        font-size: 15px !important;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px !important;
        font-weight: 500 !important;
    }
    .game-des p {
        margin-bottom: 10px;
    }
}

@media (min-width: 451px) and (max-width: 499px) {
    .student-project2-container .sp2-grid {
        margin-top: 30px;
    }
    .singel-student-project2 {
        width: 195px;
    }
    .student-project2-frame {
        height: 125px;
        width: 195px;
        margin-bottom: 5px;
    }
    .student-project2-container .sp2-title {
        font-weight: bold;
        font-size: 18px !important;
    }
    .student-project2-container .sp2-name {
        font-size: 16px !important;
    }
    .student-project2-container .sp2-played {
        font-size: 13px !important;
    }
    .student-project2-container button {
        width: 150px;
        height: 35px;
        margin-top: 60px;
    }
    .student-project2-container .note {
        font-size: 14px !important;
        margin-top: 10px;
    }
    .student-project2-container .result-btn {
        width: 150px;
    }
    .iframe-wrapper {
        width: 100%;
        height: 254px;
        overflow: hidden;
    }
    .iframe-wrapper-small {
        width: 100%;
        height: 245px;
        overflow: hidden;
    }
    .MuiDialog-root iframe {
        width: 100% !important;
        height: 290%;
        margin-top: -56px;
    }
    .sp-dialog {
        height: 500px !important;
    }
    .game-close-button {
        margin-left: 95% !important;
        margin-top: -20px !important;
    }
    .game-title {
        font-size: 23px !important;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 5px !important;
    }
    .game-name {
        font-size: 18px !important;
        margin-left: 20px;
        font-weight: 500 !important;
    }
    .game-played {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
    .game-des {
        font-size: 15px !important;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px !important;
        font-weight: 500 !important;
    }
    .game-des p {
        margin-bottom: 10px;
    }
}

@media (min-width: 500px) and (max-width: 599px) {
    .student-project2-container .sp2-grid {
        margin-top: 30px;
    }
    .singel-student-project2 {
        width: 220px;
    }
    .student-project2-frame {
        height: 130px;
        width: 220px;
        margin-bottom: 5px;
    }
    .student-project2-container .sp2-title {
        font-weight: bold;
        font-size: 18px !important;
    }
    .student-project2-container .sp2-name {
        font-size: 16px !important;
    }
    .student-project2-container .sp2-played {
        font-size: 14px !important;
    }
    .student-project2-container button {
        width: 150px;
        height: 35px;
        margin-top: 60px;
    }
    .student-project2-container .note {
        font-size: 14px !important;
        margin-top: 10px;
    }
    .student-project2-container .result-btn {
        width: 150px;
    }
    .iframe-wrapper {
        width: 100%;
        height: 282px;
        overflow: hidden;
    }
    .iframe-wrapper-small {
        width: 100%;
        height: 275px;
        overflow: hidden;
    }
    .MuiDialog-root iframe {
        width: 100% !important;
        height: 290%;
        margin-top: -56px;
    }
    .sp-dialog {
        height: 500px !important;
    }
    .game-close-button {
        margin-left: 95% !important;
        margin-top: -20px !important;
    }
    .game-title {
        font-size: 23px !important;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 5px !important;
    }
    .game-name {
        font-size: 18px !important;
        margin-left: 20px;
        font-weight: 500 !important;
    }
    .game-played {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
    .game-des {
        font-size: 15px !important;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px !important;
        font-weight: 500 !important;
    }
    .game-des p {
        margin-bottom: 10px;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .student-project2-container .sp2-grid {
        margin-top: 30px;
    }
    .singel-student-project2 {
        width: 130px;
    }
    .student-project2-frame {
        height: 80px;
        width: 130px;
        margin-bottom: 5px;
    }
    .student-project2-container .sp2-title {
        font-weight: bold;
        font-size: 15px !important;
    }
    .student-project2-container .sp2-name {
        font-size: 13px !important;
    }
    .student-project2-container .sp2-played {
        font-size: 10px !important;
    }
    .student-project2-container button {
        width: 150px;
        height: 35px;
        margin-top: 60px;
    }
    .student-project2-container .note {
        font-size: 14px !important;
        margin-top: 10px;
    }
    .student-project2-container .result-btn {
        width: 150px;
    }
    .iframe-wrapper {
        width: 580px;
        height: 312px;
        margin: 20px auto;
        overflow: hidden;
    }
    .iframe-wrapper-small {
        width: 550px;
        height: 309px;
        margin: 20px auto;
        overflow: hidden;
    }
    .MuiDialog-root iframe {
        width: 550px !important;
        height: 290%;
        margin-top: -56px;
    }
    .sp-dialog {
        height: 500px !important;
    }
    .game-close-button {
        margin-left: 95% !important;
        margin-top: -20px !important;
    }
    .MuiAppBar-colorPrimary {
        height: 60px !important;
    }
    .game-title {
        font-size: 23px !important;
        width: 550px;
        margin: 20px auto 5px auto !important;
    }
    .game-name {
        font-size: 18px !important;
        font-weight: 500 !important;
        width: 550px;
        margin: 0 auto 20px auto !important;
    }
    .game-played {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
    .game-des {
        width: 550px;
        font-size: 15px !important;
        margin: 0 auto 20px auto !important;
        font-weight: 500 !important;
    }
    .game-des p {
        margin-bottom: 10px;
    }
}

@media (min-width: 700px) and (max-width: 799px) {
    .student-project2-container .sp2-grid {
        margin-top: 30px;
    }
    .singel-student-project2 {
        width: 150px;
    }
    .student-project2-frame {
        height: 90px;
        width: 150px;
        margin-bottom: 5px;
    }
    .student-project2-container .sp2-title {
        font-weight: bold;
        font-size: 15px !important;
    }
    .student-project2-container .sp2-name {
        font-size: 14px !important;
    }
    .student-project2-container .sp2-played {
        font-size: 10px !important;
    }
    .student-project2-container button {
        width: 150px;
        height: 35px;
        margin-top: 65px;
    }
    .student-project2-container .note {
        font-size: 14px !important;
        margin-top: 10px;
    }
    .student-project2-container .result-btn {
        width: 150px;
    }
    .iframe-wrapper {
        width: 650px;
        height: 368px;
        margin: 40px auto 0 auto;
        overflow: hidden;
    }
    .iframe-wrapper-small {
        width: 650px;
        height: 365px;
        margin: 40px auto 0 auto;
        overflow: hidden;
    }
    .MuiDialog-root iframe {
        width: 100% !important;
        height: 290%;
        margin-top: -56px;
    }
    .sp-dialog {
        height: 500px !important;
    }
    .game-close-button {
        margin-left: 95% !important;
        margin-top: -20px !important;
    }
    .MuiAppBar-colorPrimary {
        height: 60px !important;
    }
    .game-title {
        width: 620px;
        font-size: 23px !important;
        margin: 20px auto 5px auto !important;
    }
    .game-name {
        width: 620px;
        font-size: 18px !important;
        margin: 0 auto 15px auto;
        font-weight: 500 !important;
    }
    .game-played {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
    .game-des {
        width: 620px;
        font-size: 15px !important;
        margin: 0 auto 20px auto !important;
        font-weight: 500 !important;
    }
    .game-des p {
        margin-bottom: 10px;
    }
}

@media (min-width: 800px) and (max-width: 899px) {
    .student-project2-container .sp2-grid {
        margin-top: 30px;
    }
    .singel-student-project2 {
        width: 150px;
    }
    .student-project2-frame {
        height: 90px;
        width: 150px;
        margin-bottom: 5px;
    }
    .student-project2-container .sp2-title {
        font-weight: bold;
        font-size: 15px !important;
    }
    .student-project2-container .sp2-name {
        font-size: 14px !important;
    }
    .student-project2-container .sp2-played {
        font-size: 10px !important;
    }
    .student-project2-container button {
        width: 150px;
        height: 35px;
        margin-top: 65px;
    }
    .student-project2-container .note {
        font-size: 14px !important;
        margin-top: 10px;
    }
    .student-project2-container .result-btn {
        width: 150px;
    }
    .iframe-wrapper {
        width: 650px;
        height: 365px;
        margin: 40px auto 0 auto;
        overflow: hidden;
    }
    .iframe-wrapper-small {
        width: 650px;
        height: 350px;
        margin: 40px auto 0 auto;
        overflow: hidden;
    }
    .MuiDialog-root iframe {
        width: 100% !important;
        height: 290%;
        margin-top: -56px;
    }
    .sp-dialog {
        height: 500px !important;
    }
    .game-close-button {
        margin-left: 95% !important;
        margin-top: -20px !important;
    }
    .MuiAppBar-colorPrimary {
        height: 60px !important;
    }
    .game-title {
        width: 620px;
        font-size: 23px !important;
        margin: 20px auto 5px auto !important;
    }
    .game-name {
        width: 620px;
        font-size: 18px !important;
        margin: 0 auto 10px auto;
        font-weight: 500 !important;
    }
    .game-played {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
    .game-des {
        width: 620px;
        margin: 0 auto 20px auto;
        font-size: 15px !important;
        font-weight: 500 !important;
    }
    .game-des p {
        margin-bottom: 10px;
    }
}

@media (min-width: 900px) and (max-width: 1024px) {
    .student-project2-container .sp2-grid {
        margin-top: 30px;
    }
    .singel-student-project2 {
        width: 200px;
    }
    .student-project2-frame {
        height: 120px;
        width: 200px;
        margin-bottom: 5px;
    }
    .student-project2-container .sp2-title {
        font-weight: bold;
        font-size: 16px !important;
    }
    .student-project2-container .sp2-name {
        font-size: 15px !important;
    }
    .student-project2-container .sp2-played {
        font-size: 12px !important;
    }
    .student-project2-container button {
        width: 150px;
        height: 35px;
        margin-top: 65px;
    }
    .student-project2-container .note {
        font-size: 14px !important;
        margin-top: 10px;
    }
    .student-project2-container .result-btn {
        width: 150px;
    }
    .iframe-wrapper {
        width: 650px;
        height: 365px;
        margin: 40px auto 0 auto;
        overflow: hidden;
    }
    .iframe-wrapper-small {
        width: 650px;
        height: 350px;
        margin: 40px auto 0 auto;
        overflow: hidden;
    }
    .MuiDialog-root iframe {
        width: 100% !important;
        height: 290%;
        margin-top: -56px;
    }
    .sp-dialog {
        height: 500px !important;
    }
    .game-close-button {
        margin-left: 95% !important;
        margin-top: -20px !important;
    }
    .MuiAppBar-colorPrimary {
        height: 60px !important;
    }
    .game-title {
        width: 620px;
        font-size: 23px !important;
        margin: 20px auto 5px auto !important;
    }
    .game-name {
        width: 620px;
        font-size: 18px !important;
        margin: 0 auto 10px auto;
        font-weight: 500 !important;
    }
    .game-played {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
    .game-des {
        width: 620px;
        margin: 0 auto 20px auto;
        font-size: 15px !important;
        font-weight: 500 !important;
    }
    .game-des p {
        margin-bottom: 10px;
    }
}

@media (min-width: 1025px) {
    .student-project2-container .sp2-grid {
        margin-top: 30px;
    }
    .singel-student-project2 {
        width: 220px;
    }
    .student-project2-frame {
        height: 130px;
        width: 220px;
        margin-bottom: 5px;
    }
    .student-project2-container .sp2-title {
        font-weight: bold;
        font-size: 16px !important;
    }
    .student-project2-container .sp2-name {
        font-size: 16px !important;
    }
    .student-project2-container .sp2-played {
        font-size: 12px !important;
    }
    .student-project2-container button {
        width: 150px;
        height: 35px;
        margin-top: 65px;
    }
    .student-project2-container .note {
        font-size: 14px !important;
        margin-top: 10px;
    }
    .student-project2-container .result-btn {
        width: 150px;
    }
    .iframe-wrapper {
        height: 395px;
        width: 700px;
        margin: 40px auto 0 auto;
        overflow: hidden;
    }
    .iframe-wrapper-small {
        height: 390px;
        width: 700px;
        margin: 40px auto 0 auto;
        overflow: hidden;
    }
    .MuiDialog-root iframe {
        width: 700px !important;
        height: 200%;
        margin-top: -56px;
    }
    .sp-dialog {
        height: 500px !important;
    }
    .game-close-button {
        margin-left: 95% !important;
        margin-top: -10px !important;
    }
    .MuiAppBar-colorPrimary {
        height: 60px !important;
    }
    .game-title {
        width: 700px;
        font-size: 23px !important;
        margin: 20px auto 5px auto !important;
    }
    .game-name {
        width: 700px;
        margin: 5px auto 20px auto !important;
        font-size: 18px !important;
        font-weight: 500 !important;
    }
    .game-played {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
    .game-des {
        width: 700px;
        font-size: 15px !important;
        margin: 0 auto 10px auto !important;
        font-weight: 500 !important;
    }
    .game-des p {
        margin-bottom: 10px;
    }
}

@media (min-width: 1100px) {
    .student-project2-container {
        width: 940px;
    }
    .student-project2-container h1{
        margin-top: 200px ;
    }
}