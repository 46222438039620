.tab-container {
    overflow: hidden;
}

.tab-container .nav-link {
    text-align: center;
}

.tab-container .carousel img {
    width: 100%;
}

.tab-container .nav-link {
    border: #303030 solid 1px;
    border-radius: 2px;
    color: #303030;
}

.tab-container .nav-link:hover {
    background-color: #1665A8;
    color: white !important;
}

.tab-container .game-gif-1{
    background-color: #2A63D9 ;
}
.tab-container .game-gif-2{
    background-color: #599CB5 ;
}
.tab-container .game-gif-3{
    background-color: #31BDF0;
}

.tab-container .game-gif-4{
    background-color: #6B310A;
}
.tab-container .game-gif-5{
    background-color: #CEF0EF;
}

.tab-container .game-gif-1 img,
.tab-container .game-gif-2 img,
.tab-container .game-gif-3 img,
.tab-container .game-gif-4 img,
.tab-container .game-gif-5 img
{
    width: 100%;
}


@media (min-width: 320px) and (max-width: 374px) {
    .tab-container {
        margin: 60px auto;
    }
    .tab-container h1 {
        margin-bottom: 30px;
    }
    .tab-container .nav-link {
        padding: 0px;
        margin: 0px 1px !important;
        width: 62px !important;
        font-size: 12px;
    }
    .tab-container .carousel {
        margin: 0 auto;
        width: 100%;
    }
    .tab-row {
        width: 330px !important;
        margin: 0 auto;
    }
    .tab-row .row{
        width: 340px !important;
        margin: 0 auto;
    }
    .tab-container .carousel-control-next-icon, .tab-container .carousel-control-prev-icon {
        height: 40px;
        width: 30px;
    }
    .tab-container .game-gif-1,
    .tab-container .game-gif-2,
    .tab-container .game-gif-3,
    .tab-container .game-gif-4,
    .tab-container .game-gif-5
    {
        width: 100%;
        margin: 20px auto 0 auto;
        padding: 30px;
    }
}

@media (min-width: 375px) and (max-width: 413px) {
    .tab-container {
        margin: 60px auto;
    }
    .tab-container h1 {
        margin-bottom: 30px;
    }
    .tab-container .nav-link {
        padding: 0px;
        margin: 0px 1px !important;
        width: 65px !important;
        font-size: 14px;
    }
    .tab-container .carousel {
        margin: 0 auto;
        width: 100%;
    }
    .tab-row {
        width: 330px !important;
        margin: 0 auto;
    }
    .tab-row .row{
        width: 340px !important;
        margin: 0 auto;
    }
    .tab-container .carousel-control-next-icon, .tab-container .carousel-control-prev-icon {
        height: 40px;
        width: 30px;
    }
    .tab-container .game-gif-1,
    .tab-container .game-gif-2,
    .tab-container .game-gif-3,
    .tab-container .game-gif-4,
    .tab-container .game-gif-5
    {
        width: 100%;
        margin: 30px auto 0 auto;
        padding: 30px;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone 11 size >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 414px) and (max-width: 428px) {
    .tab-container {
        margin: 80px auto;
    }
    .tab-container h1 {
        margin-bottom: 30px;
    }
    .tab-container .nav-link {
        padding: 2px;
        margin: 0px 1px !important;
        width: 65px !important;
        font-size: 15px;
    }
    .tab-container .carousel {
        margin: 0 auto;
        width: 100%;
    }
    .tab-row {
        width: 330px !important;
        margin: 0 auto;
    }
    .tab-row .row{
        width: 340px !important;
        margin: 0 auto;
    }
    .tab-container .carousel-control-next-icon, .tab-container .carousel-control-prev-icon {
        height: 40px;
        width: 30px;
    }
    .tab-container .game-gif-1,
    .tab-container .game-gif-2,
    .tab-container .game-gif-3,
    .tab-container .game-gif-4,
    .tab-container .game-gif-5
    {
        width: 100%;
        margin: 35px auto 0 auto;
        padding: 30px;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< iphone pro max >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 429px) and (max-width: 450px) {
    .tab-container {
        margin: 80px auto;
    }
    .tab-container h1 {
        margin-bottom: 30px;
    }
    .tab-container .nav-link {
        padding: 2px;
        margin: 0px 1px !important;
        width: 65px !important;
        font-size: 15px;
    }
    .tab-container .carousel {
        margin: 0 auto;
        width: 100%;
    }
    .tab-row {
        width: 340px !important;
        margin: 0 auto;
    }
    .tab-row .row{
        width: 350px !important;
        margin: 0 auto;
    }
    .tab-container .carousel-control-next-icon, .tab-container .carousel-control-prev-icon {
        height: 40px;
        width: 30px;
    }
    .tab-container .game-gif-1,
    .tab-container .game-gif-2,
    .tab-container .game-gif-3,
    .tab-container .game-gif-4,
    .tab-container .game-gif-5
    {
        width: 100%;
        margin: 35px auto 0 auto;
        padding: 30px;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< other bigger than iphone pro >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 451px) and (max-width: 599px) {
    .tab-container {
        margin: 80px auto;
    }
    .tab-container h1 {
        margin-bottom: 30px;
    }
    .tab-container .nav-link {
        padding: 3px;
        margin: 0px 1px !important;
        width: 70px !important;
        font-size: 15px;
    }
    .tab-container .carousel {
        margin: 0 auto;
        width: 100%;
    }
    .tab-row {
        width: 360px !important;
        margin: 0 auto;
    }
    .tab-row .row{
        width: 370px !important;
        margin: 0 auto;
    }
    .tab-container .carousel-control-next-icon, .tab-container .carousel-control-prev-icon {
        height: 45px;
        width: 35px;
    }
    .tab-container .game-gif-1,
    .tab-container .game-gif-2,
    .tab-container .game-gif-3,
    .tab-container .game-gif-4,
    .tab-container .game-gif-5
    {
        width: 100%;
        margin: 35px auto 0 auto;
        padding: 30px;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .tab-container {
        margin: 80px auto;
    }
    .tab-container h1 {
        margin-bottom: 30px;
    }
    .tab-container .nav-link {
        padding: 3px;
        margin: 0px 2px !important;
        width: 80px !important;
        font-size: 15px;
    }
    .tab-container .carousel {
        margin: 0 auto;
        width: 600px;
    }
    .tab-row {
        width: 430px !important;
        margin: 0 auto;
    }
    .tab-row .row{
        width: 450px !important;
        margin: 0 auto;
    }
    .tab-container .carousel-control-next-icon, .tab-container .carousel-control-prev-icon {
        height: 50px;
        width: 40px;
    }
    .tab-container .game-gif-1,
    .tab-container .game-gif-2,
    .tab-container .game-gif-3,
    .tab-container .game-gif-4,
    .tab-container .game-gif-5
    {
        width: 600px;
        margin: 45px auto 0 auto;
        padding: 50px;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<< ipad min >>>>>>>>>>>>>>>>>>>>>> */

@media (min-width: 700px) and (max-width: 959px) {
    .tab-container {
        margin: 80px auto;
    }
    .tab-container h1 {
        margin-bottom: 30px;
    }
    .tab-container .nav-link {
        padding: 3px;
        margin: 0px 4px !important;
        width: 70px !important;
        font-size: 15px;
    }
    .tab-container .carousel {
        margin: 0 auto;
        width: 600px;
    }
    .tab-row {
        width: 400px !important;
        margin: 0 auto;
    }
    .tab-row .row{
        width: 450px !important;
        margin: 0 auto;
    }
    .tab-container .carousel-control-next-icon, .tab-container .carousel-control-prev-icon {
        height: 50px;
        width: 40px;
    }
    .tab-container .game-gif-1,
    .tab-container .game-gif-2,
    .tab-container .game-gif-3,
    .tab-container .game-gif-4,
    .tab-container .game-gif-5
    {
        width: 600px;
        margin: 50px auto 0 auto;
        padding: 50px;
    }
}

@media (min-width: 960px) and (max-width: 1023px) {
    .tab-container {
        margin: 80px auto;
    }
    .tab-container h1 {
        margin-bottom: 30px;
    }
    .tab-container .nav-link {
        padding: 3px;
        margin: 0px 5px !important;
        width: 70px !important;
        font-size: 17px;
    }
    .tab-container .carousel {
        margin: 0 auto;
        width: 600px;
    }
    .tab-row {
        width: 400px !important;
        margin: 0 auto;
    }
    .tab-row .row{
        width: 460px !important;
        margin: 0 auto;
    }
    .tab-container .carousel-control-next-icon, .tab-container .carousel-control-prev-icon {
        height: 55px;
        width: 45px;
    }
    .tab-container .game-gif-1,
    .tab-container .game-gif-2,
    .tab-container .game-gif-3,
    .tab-container .game-gif-4,
    .tab-container .game-gif-5
    {
        width: 600px;
        margin: 50px auto 0 auto;
        padding: 50px;
    }
}

@media (min-width: 1024px) {
    .tab-container {
        margin: 80px auto;
    }
    .tab-container h1 {
        margin-bottom: 30px;
    }
    .tab-container .nav-link {
        padding: 3px;
        margin: 0px 3px !important;
        width: 80px !important;
        font-size: 18px;
    }
    .tab-container .carousel {
        margin: 0 auto;
        width: 600px;
    }
    .tab-row {
        width: 400px !important;
        margin: 0 auto;
    }
    .tab-row .row{
        width: 460px !important;
        margin: 0 auto;
    }
    .tab-container .carousel-control-next-icon, .tab-container .carousel-control-prev-icon {
        height: 55px;
        width: 45px;
    }
    .tab-container .game-gif-1,
    .tab-container .game-gif-2,
    .tab-container .game-gif-3,
    .tab-container .game-gif-4,
    .tab-container .game-gif-5
    {
        width: 600px;
        margin: 50px auto 0 auto;
        padding: 50px;
    }
}

@media (min-width: 1150px) {
    .tab-container {
        margin: 80px auto;
    }
    .tab-container h1 {
        margin-bottom: 30px;
    }
    .tab-container .nav-link {
        padding: 3px;
        margin: 0px 3px !important;
        width: 80px !important;
        font-size: 18px;
    }
    .tab-container .carousel {
        margin: 0 auto;
        width: 700px;
    }
    .tab-row {
        width: 400px !important;
        margin: 0 auto;
    }
    .tab-row .row{
        width: 460px !important;
        margin: 0 auto;
    }
    .tab-container .carousel-control-next-icon, .tab-container .carousel-control-prev-icon {
        height: 55px;
        width: 45px;
    }
    .tab-container .game-gif-1,
    .tab-container .game-gif-2,
    .tab-container .game-gif-3,
    .tab-container .game-gif-4,
    .tab-container .game-gif-5
    {
        width: 700px;
        margin: 50px auto 0 auto;
        padding: 80px;
    }
}

