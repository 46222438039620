.footer-container {
    background-color: #FFDC48;
    padding: 4rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-container span{
    text-align: center;
}

.footer-container .MuiGrid-spacing-xs-4>.MuiGrid-item {
    padding: 5px !important;
}

/* footer table of content */

.footer-table {
    width: 70%;
    margin-left: 5vw;
}

/* footer bottom's logo and phone cand line */

.footer-contact {
    text-align: center;
    width: 60%;
    margin-top: 5vh;
}

.footer-title {
    font-size: 3vh;
    font-weight: bold;
    color: rgb(82, 82, 82);
    text-decoration: none !important;
}

.footer-line-button {
    background-color: #00b900;
    color: white;
    margin: 0 5px;
    padding: 4px 6px;
    border-radius: 5px;
}

.website-rights {
    color: rgb(48, 48, 48);
    margin-bottom: 10px;
}

.footer-policy a {
    color: rgb(48, 48, 48) !important;
}


@media (min-width: 320px) and (max-width: 370px) {
    .bottom-text span {
        font-size: 14px !important;
        margin: 0 auto;
    }
    .footer-table {
        margin-left: 14px;
        width: 70%;
    }
    .footer-table a{
        font-size: 10px !important;
    }
    .footer-title {
        margin-bottom: 50px;
    }
    .footer-phone {
        width: 200px !important;
        margin: 40px auto 10px auto;
    }
    .footer-contact {
        text-align: center;
        width: 280px;
        font-size: 13px;
        color: rgb(48, 48, 48) !important;
        margin-bottom: 30px;
        margin-top: 80px;
    }
    .footer-line-button {
        color: white;
        margin: 0px 5px;
        padding: 4px 6px;
        border-radius: 3px;
        font-size: 12px;
    }
    .website-rights {
        font-size: 12px !important;
        margin-bottom: 0px;
        margin-top: 20px;
    }
    .footer-policy {
        margin: 5px auto 5px auto !important;
        width: 270px !important;
    }
}

@media (min-width: 371px) and (max-width: 413px) {
    .footer-contact {
        text-align: center;
        width: 300px;
        margin-bottom: 30px;
        margin-top: 80px;
    }
    .bottom-text span {
        font-size: 14px !important;
    }
    .footer-table {
        margin-left: 50px;
        width: 80%;
    }
    .footer-phone {
        width: 200px !important;
        margin: 30px auto 10px auto;
    }
    .footer-line-button {
        font-size: 12px;
        color: white;
        margin: 0 5px;
        padding: 4px 6px;
        border-radius: 5px;
    }
    .footer-policy {
        margin: 0 auto 5px auto !important;
        width: 300px !important;
    }
    .website-rights {
        font-size: 12px !important;
        margin-bottom: 0px;
        margin-top: 20px;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .footer-contact {
        text-align: center;
        width: 320px;
        margin-bottom: 50px;
        margin-top: 80px;
    }
    .bottom-text span {
        font-size: 14px !important;
    }
    .footer-table {
        margin-left: 60px;
        width: 80%;
    }
    .footer-phone {
        width: 200px !important;
        margin: 40px auto 0 auto;
    }
    .footer-line-button {
        font-size: 12px;
        color: white;
        margin: 0 5px;
        padding: 4px 6px;
        border-radius: 5px;
    }
    .footer-policy {
        margin: 10px auto 5px auto !important;
        width: 280px !important;
    }
    .website-rights {
        font-size: 12px !important;
        margin-top: 20px;
    }
}

@media (min-width: 429px) and (max-width: 450px) {
    .footer-contact {
        text-align: center;
        width: 320px;
        margin-bottom: 30px;
        margin-top: 80px;
    }
    .bottom-text span {
        font-size: 14px !important;
    }
    .footer-table {
        margin-left: 70px;
        width: 80%;
    }
    .footer-phone {
        width: 250px !important;
        margin: 40px auto 0 auto;
    }
    .footer-line-button {
        font-size: 13px;
        color: white;
        margin: 0 5px;
        padding: 4px 6px;
        border-radius: 5px;
    }
    .footer-policy {
        margin: 10px auto 5px auto !important;
        width: 320px !important;
    }
    .website-rights {
        font-size: 13px !important;
        margin-bottom: 0px;
        margin-top: 40px;
    }
}


@media (min-width: 451px) and (max-width: 499px) {
    .footer-contact {
        text-align: center;
        width: 320px;
        margin-bottom: 30px;
        margin-top: 80px;
    }
    .bottom-text span {
        font-size: 14px !important;
        margin-right: 2vh;
    }
    .footer-table {
        margin-left: 70px;
        width: 75%;
    }
    .footer-phone {
        width: 250px !important;
        margin: 40px auto 0 auto;
    }
    .footer-line-button {
        font-size: 13px;
        color: white;
        margin: 0 5px;
        padding: 4px 6px;
        border-radius: 5px;
    }
    .footer-policy {
        margin: 10px auto 5px auto !important;
        width: 320px !important;
    }
    .website-rights {
        font-size: 13px !important;
        margin-bottom: 0px;
        margin-top: 40px;
    }
}

@media (min-width: 500px) and (max-width: 599px) {
    .footer-contact {
        text-align: center;
        width: 320px;
        margin-bottom: 30px;
        margin-top: 80px;
    }
    .bottom-text span {
        font-size: 14px !important;
        margin-right: 2vh;
    }
    .footer-table {
        margin-left: 90px;
        width: 78%;
    }
    .footer-phone {
        width: 250px !important;
        margin: 50px auto 0 auto;
    }
    .footer-line-button {
        font-size: 13px;
        color: white;
        margin: 0 5px;
        padding: 4px 6px;
        border-radius: 5px;
    }
    .footer-policy {
        margin: 10px auto 5px auto !important;
        width: 320px !important;
    }
    .website-rights {
        font-size: 13px !important;
        margin-bottom: 0px;
        margin-top: 40px;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .footer-container h2{
        font-size: 26px !important;
    }
    .footer-contact {
        text-align: center;
        width: 360px;
        margin-bottom: 20px;
        margin-top: 80px;
    }
    .bottom-text span {
        font-size: 14px !important;
    }
    .footer-table {
        margin-left: 10px;
        width: 85%;
    }
    .footer-line-button {
        font-size: 13px;
        color: white;
        margin: 0 5px;
        padding: 4px 6px;
        border-radius: 5px;
    }
    .footer-phone {
        width: 300px !important;
        margin: 40px auto 10px auto;
    }
    .footer-policy {
        margin: 15px auto 5px auto !important;
        width: 360px !important;
    }
    .website-rights {
        font-size: 13px !important;
        margin-bottom: 0px;
        margin-top: 40px;
    }
}

@media (min-width: 700px) and (max-width: 959px) {
    .footer-table {
        width: 85%;
    }
    .footer-contact {
        margin: 7vh 0 1vh 0;
        text-align: center;
        width: 400px;
    }
    .footer-line-button {
        margin: 0 2px;
        padding: 4px 6px;
        border-radius: 4px;
    }
    .footer-phone {
        width: 300px !important;
        margin: 40px auto 10px auto;
    }
    .footer-policy {
        margin: 15px auto 5px auto !important;
        width: 360px !important;
    }
    .website-rights {
        font-size: 13px !important;
        margin-bottom: 0px;
        margin-top: 30px;
    }
}

@media (min-width: 960px) and (max-width: 1023px) {
    .footer-table {
        width: 85%;
        margin-bottom: 30px;
    }
    .footer-contact {
        margin: 7vh 0 1vh 0;
        text-align: center;
        width: 680px;
    }
    .footer-phone {
        width: 280px !important;
        margin: 40px auto 5px auto;
    }
    .footer-line-button {
        margin: 0 2px;
        padding: 4px 6px;
        border-radius: 4px;
    }
    .footer-policy {
        margin: 15px auto !important;
        width: 350px !important;
    }
    .website-rights {
        margin-top: 30px;
    }
}

@media (min-width: 1024px) {
    .footer-table {
        width: 800px;
        margin-bottom: 50px;
    }
    .footer-contact {
        margin: 7vh 0 1vh 0;
        text-align: center;
        width: 700px;
    }
    .footer-phone {
        width: 280px !important;
        margin: 50px auto 5px auto;
    }
    .footer-line-button {
        margin: 0 2px;
        padding: 4px 6px;
        border-radius: 4px;
    }
    .footer-policy {
        margin: 15px auto !important;
        width: 380px !important;
        font-size: 16px;
    }
    .website-rights {
        margin-top: 40px;
    }
}

@media (min-width: 1500px) {
    .footer-table {
        width: 900px;
    }
}