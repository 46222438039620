.alllocations-container {
    margin: 0 auto;
    width: 80%;
    margin-top: 150px !important;
    margin-bottom: 150px !important;
}

.alllocations-container h1 {
    margin-bottom: 50px;
}

.locations-frame {
    height: 15vh;
}

body, html {
    height: auto;
}

.locations-frame-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (min-width: 320px) and (max-width: 374px) {
    .locations-frame {
        height: 210px;
    }
    .locations-frame-img {
        height: 22vh;
    }
    .MuiAccordionSummary-root {
        height: 30px !important;
    }
    .locations-frame-img {
        height: 150px;
        width: 250px;
        margin: 0 auto;
        overflow: hidden;
    }
}

@media (min-width: 375px) and (max-width: 413px) {
    .locations-frame {
        height: 210px;
    }
    .MuiAccordionSummary-root {
        height: 30px !important;
    }
    .locations-frame-img {
        height: 150px;
        width: 250px;
        margin: 0 auto;
        overflow: hidden;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .locations-frame {
        height: 220px;
    }
    .locations-frame-img {
        height: 160px;
        width: 290px;
        margin: 0 auto;
        overflow: hidden;
    }
    .MuiAccordionSummary-root {
        height: 30px !important;
    }
}

@media (min-width: 429px) and (max-width: 450px) {
    .locations-frame {
        height: 240px;
    }
    .locations-frame-img {
        height: 180px;
        width: 290px;
        margin: 0 auto;
        overflow: hidden;
    }
    .MuiAccordionSummary-root {
        height: 30px !important;
    }
}

@media (min-width: 451px) and (max-width: 499px) {
    .locations-frame {
        height: 250px;
    }
    .locations-frame-img {
        height: 190px;
        width: 300px;
        margin: 0 auto;
        overflow: hidden;
    }
}

@media (min-width: 500px) and (max-width: 599px) {
    .locations-frame {
        height: 250px;
    }
    .locations-frame-img {
        height: 190px;
        width: 300px;
        margin: 0 auto;
        overflow: hidden;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .locations-frame {
        height: 190px;
    }
    .locations-frame-img {
        height: 130px;
        width: 200px;
        margin: 0 auto;
        overflow: hidden;
    }
    .MuiAccordionSummary-root {
        height: 30px !important;
    }
}

@media (min-width: 700px) and (max-width: 959px) {
    .locations-frame {
        height: 220px;
    }
    .locations-frame-img {
        height: 160px;
        width: 250px;
        margin: 0 auto;
        overflow: hidden;
    }
    .MuiAccordionSummary-root {
        height: 30px !important;
    }
}

@media (min-width: 960px) and (max-width: 1024px) {
    .locations-frame {
        height: 160px;
    }
    .locations-frame-img {
        height: 100px;
        width: 170px;
        margin: 0 auto;
        overflow: hidden;
    }
    .MuiAccordionSummary-root {
        height: 30px !important;
    }
}

@media (min-width: 1025px) {
    .locations-frame {
        height: 180px;
    }
    .locations-frame-img {
        height: 120px;
        width: 180px;
        margin: 0 auto;
        overflow: hidden;
    }
    .MuiAccordionSummary-root {
        height: 30px !important;
    }
    .alllocations-container {
        margin: 0 auto;
        width: 800px;
        margin-top: 150px !important;
    }
    .alllocations-container .MuiCollapse-container p{
        font-size: 16px !important;
    }
}
