.student-project-container {
    margin: 10vh auto 1vh auto!important;
    width: 80%;
    height: 100%;
}

.student-project1-frame {
    position: relative;
}

.student-project1-img {
    border-radius: 50%;
    overflow: hidden;
    width: 90%;
    margin: 20px auto;
}

.student-project1-img img {
    width: 100%;
    margin: 0 auto;
}

.student-project1-text {
    border-radius: 20px;
    overflow: hidden;
    position: absolute;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.student-project1-des {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
}

.student-project1-name {
    color: white;
    background-color: #1665A8 
    ;
    margin-bottom: 0px !important;
}

.student-project1-name a:hover{
    color: #b8e3f3 !important;
}

.more-result-btn a{
    color: #303030 !important;
}


@media (min-width: 320px) and (max-width: 374px) {
    .MuiGrid-spacing-xs-5>.MuiGrid-item {
        margin: 0 6vh;
        padding: 0px !important;
    }
    .student-project1-img {
       height: 260px;
       width: 260px;
    }
    .student-project-container {
        margin: 50px auto!important;
        width: 90%;
        height: 100%;
    }
    .student-project1-frame {
        height: 100%;
        width: 100%;
        margin-bottom: 40px;
    }
    .student-project1-text {
        width: 180px;
        height: 120px;
        right: 0px;
        top: 200px;
    }
    .student-project1-des {
        width: 180px;
        height: 90px;
        padding: 10px 15px;
    }
    .student-project1-name {
        font-size: 30px;
        width: 180px;
        height: 40px;
        padding-top: 10px;
    }
    .student-project1-name a{
        font-size: 18px;
        color: white;
    }
    .more-result-btn{
        width: 180px;
        height: 40px;
        margin-top: 30px !important;
    }
}

@media (min-width: 375px) and (max-width: 413px) {
    .MuiGrid-spacing-xs-5>.MuiGrid-item {
        margin: 0 6vh;
        padding: 0px !important;
    }
    .student-project1-img {
        height: 290px;
        width: 290px;
    }
    .student-project-container {
        margin: 8vh auto 8vh auto!important;
        width: 90%;
        height: 100%;
    }
    .student-project1-frame {
        margin-bottom: 40px;
    }
    .student-project1-text {
        width: 180px;
        height: 120px;
        right: 0px;
        top: 220px;
    }
    .student-project1-des {
        width: 180px;
        height: 90px;
        padding: 10px 15px;
    }
    .student-project1-name {
        font-size: 30px;
        width: 180px;
        height: 40px;
        padding-top: 10px;
    }
    .student-project1-name a{
        font-size: 18px;
        color: white;
    }
    .more-result-btn {
        width: 180px;
        height: 40px;
        margin-top: 80px !important;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .MuiGrid-spacing-xs-5>.MuiGrid-item {
        margin: 0 6vh;
        padding: 0px !important;
    }
    .student-project1-img {
        height: 340px;
        width: 340px;
    }
    .student-project-container {
        margin: 8vh auto 8vh auto!important;
        width: 90%;
        height: 100%;
    }
    .student-project1-frame {
        margin-bottom: 40px;
    }
    .student-project1-text {
        width: 200px;
        height: 120px;
        right: 0px;
        top: 260px;
    }
    .student-project1-des {
        width: 200px;
        height: 90px;
        padding: 10px 15px;
    }
    .student-project1-name {
        font-size: 30px;
        width: 200px;
        height: 40px;
        padding-top: 10px;
    }
    .student-project1-name a{
        font-size: 18px;
        color: white;
    }
    .more-result-btn {
        width: 180px;
        height: 40px;
        margin-top: 80px !important;
    }
}

@media (min-width: 429px) and (max-width: 499px) {
    .MuiGrid-spacing-xs-5>.MuiGrid-item {
        margin: 0 6vh;
        padding: 0px !important;
    }
    .student-project1-img {
        height: 350px;
        width: 350px;
    }
    .student-project-container {
        margin: 8vh auto 8vh auto!important;
        width: 90%;
        height: 100%;
    }
    .student-project1-frame {
        margin-bottom: 30px;
    }
    .student-project1-text {
        width: 220px;
        height: 120px;
        right: 0px;
        top: 270px;
    }
    .student-project1-des {
        width: 220px;
        height: 90px;
        padding: 10px 15px;
    }
    .student-project1-name {
        font-size: 30px;
        width: 220px;
        height: 40px;
        padding-top: 10px;
    }
    .student-project1-name a{
        font-size: 18px;
        color: white;
    }
    .more-result-btn {
        width: 180px;
        height: 40px;
        margin-top: 60px !important;
    }
}

@media (min-width: 500px) and (max-width: 599px) {
    .MuiGrid-spacing-xs-5>.MuiGrid-item {
        margin: 0 6vh;
        padding: 0px !important;
    }
    .student-project1-img {
        width:360px;
        height: 360px;
    }
    .student-project-container {
        margin: 8vh auto 8vh auto!important;
        width: 90%;
        height: 100%;
    }
    .student-project1-frame {
        margin-bottom: 30px;
    }
    .student-project1-text {
        width: 220px;
        height: 120px;
        right: 0px;
        top: 280px;
    }
    .student-project1-des {
        width: 220px;
        height: 90px;
        padding: 10px 15px;
    }
    .student-project1-name {
        font-size: 30px;
        width: 220px;
        height: 40px;
        padding-top: 10px;
    }
    .student-project1-name a{
        font-size: 18px;
        color: white;
    }
    .more-result-btn {
        width: 180px;
        height: 40px;
        margin-top: 60px !important;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .MuiGrid-spacing-xs-5>.MuiGrid-item {
        margin: 0 6vh;
        padding: 0px !important;
    }
    .student-project1-img {
        width: 360px;
        height: 360px;
    }
    .student-project-container {
        margin: 8vh auto 8vh auto!important;
        width: 90%;
        height: 100%;
    }
    .student-project1-frame {
        margin-bottom: 0px;
    }
    .student-project1-text {
        width: 210px;
        height: 120px;
        right: 50px;
        top: 280px;
    }
    .student-project1-des {
        width: 210px;
        height: 90px;
        padding: 10px 15px;
    }
    .student-project1-name {
        font-size: 30px;
        width: 210px;
        height: 40px;
        padding-top: 10px;
    }
    .student-project1-name a{
        font-size: 18px;
        color: white;
    }
    .more-result-btn {
        width: 180px;
        height: 40px;
        margin-top: 60px !important;
    }
}

@media (min-width: 700px) and (max-width: 959px) {
    .MuiGrid-spacing-xs-5>.MuiGrid-item {
        margin: 0 6vh;
        padding: 0px !important;
    }
    .student-project1-img {
        width: 360px;
        height: 360px;
    }
    .student-project-container {
        margin: 8vh auto 8vh auto!important;
        width: 90%;
        height: 100%;
    }
    .student-project1-frame {
        margin-bottom: 40px;
    }
    .student-project1-text {
        width: 210px;
        height: 120px;
        right: 20%;
        top: 280px;
    }
    .student-project1-des {
        width: 210px;
        height: 90px;
        padding: 10px 15px;
    }
    .student-project1-name {
        font-size: 30px;
        width: 210px;
        height: 40px;
        padding-top: 10px;
    }
    .student-project1-name a{
        font-size: 18px;
        color: white;
    }
    .more-result-btn {
        width: 180px;
        height: 40px;
        margin-top: 30px !important;
    }
}

@media (min-width: 960px) {
    .MuiGrid-spacing-xs-5>.MuiGrid-item {
        margin: 0 6vh;
        padding: 0px !important;
    }
    .student-project1-img {
        width: 250px;
        height: 250px;
    }
    .student-project-container {
        margin: 8vh auto 8vh auto!important;
        width: 90%;
        height: 100%;
    }
    .student-project1-frame {
        margin-bottom: 80px;
    }
    .student-project1-text {
        width: 180px;
        height: 120px;
        right: 10px;
        top: 180px;
    }
    .student-project1-des {
        width: 180px;
        height: 90px;
        padding: 10px 15px;
        font-size: 15px !important;
    }
    .student-project1-name {
        width: 180px;
        height: 40px;
        padding-top: 10px;
        font-size: 30px;
    }
    .student-project1-name a{
        font-size: 18px;
        color: white;
    }
    .more-result-btn {
        width: 180px;
        height: 40px;
        margin-top: 60px;
    }
}


@media (min-width: 1025px) {
    .student-project-container{
        width: 1000px;
    }
}


@media (min-width: 1200px) {
    .MuiGrid-spacing-xs-5>.MuiGrid-item {
        margin: 0 6vh;
        padding: 0px !important;
    }
    .student-project1-img {
        width: 300px;
        height: 300px;
    }
    .student-project-container {
        margin: 8vh auto 8vh auto!important;
        height: 100%;
    }
    .student-project1-frame {
        margin-bottom: 80px;
    }
    .student-project1-text {
        width: 220px;
        height: 120px;
        right: 5%;
        top: 210px;
    }
    .student-project1-des {
        width: 220px;
        height: 90px;
        padding: 10px 15px;
        font-size: 15px !important;
    }
    .student-project1-name {
        width: 220px;
        height: 40px;
        padding-top: 10px;
        font-size: 30px;
    }
    .student-project1-name a{
        font-size: 18px;
        color: white;
    }
    .more-result-btn {
        width: 180px;
        height: 40px;
        margin-top: 60px;
    }
}