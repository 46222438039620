@media (min-width: 320px) and (max-width: 374px) {
    .error404-container {
        padding: 50px 10px;
        width: 100%;
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .error404-container .more-btn {
        width: 120px;
        margin: 0 auto;
    }
    .error404-container .more-btn button {
        margin: 20 auto;
        width: 120px;
    }
}

@media (min-width: 375px) and (max-width: 413px) {
    .error404-container {
        padding: 80px 10px;
        width: 100%;
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .error404-container .more-btn {
        width: 120px;
        margin: 0 auto;
    }
    .error404-container .more-btn button {
        margin: 30px auto;
        width: 120px;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .error404-container {
        padding: 50px 10px;
        width: 100%;
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .error404-container .more-btn {
        width: 120px;
        margin: 0 auto;
    }
    .error404-container .more-btn button {
        margin: 20px auto;
        width: 120px;
    }
}

@media (min-width: 429px) and (max-width: 450px) {
    .error404-container {
        padding: 50px 10px;
        width: 100%;
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .error404-container .more-btn {
        width: 120px;
        margin: 0 auto;
    }
    .error404-container .more-btn button {
        margin: 20px auto;
        width: 120px;
    }
}

@media (min-width: 451px) and (max-width: 599px) {
    .error404-container {
        padding: 50px 10px;
        width: 100%;
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .error404-container .more-btn {
        width: 120px;
        margin: 0 auto;
    }
    .error404-container .more-btn button {
        margin: 20px auto;
        width: 120px;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .error404-container {
        padding: 100px 10px;
        width: 100%;
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .error404-container .more-btn {
        width: 120px;
        margin: 0 auto;
    }
    .error404-container .more-btn button {
        margin: 20px auto;
        width: 120px;
    }
}

@media (min-width: 700px) and (max-width: 959px) {
    .error404-container {
        padding: 120px 10px;
        width: 100%;
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .error404-container .more-btn {
        width: 120px;
        margin: 0 auto;
    }
    .error404-container .more-btn button {
        margin: 20px auto;
        width: 120px;
    }
}

@media (min-width: 960px) and (max-width: 1024px) {
    .error404-container {
        padding: 120px 10px;
        width: 100%;
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .error404-container .more-btn {
        width: 120px;
        margin: 0 auto;
    }
    .error404-container .more-btn button {
        margin: 20px auto;
        width: 120px;
    }
}

@media (min-width: 1025px) {
    .error404-container {
        padding: 120px 10px;
        width: 100%;
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .error404-container .more-btn {
        width: 120px;
        margin: 0 auto;
    }
    .error404-container .more-btn button {
        margin: 20px auto;
        width: 120px;
    }
}