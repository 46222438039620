.hero-container img {
    object-fit: cover;
    width: 100% !important;
    margin-top: -350px;
    position: relative;
    z-index: -2;
}

.hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    overflow: hidden !important;
    z-index: -1;
    margin-top: 120px !important;
}

.video-words {
    margin-top: -400px;
    position: relative;
    text-align: center;
}

.hero-container>h1 {
    color: #fff;
    font-size: 100px;
}

.hero-container>p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

.hero-trial-button {
    border-color: white !important;
    margin-top: 5px !important;
}

.popup-content {
    height: 50vh !important;
    color: black;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
}

.modal_content {
    background-color: white;
    position: absolute;
    top: 20%;
    left: 30%;
    width: 40%;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid black;
}

.close {
    color: Black;
    float: right;
}

.close:hover {
    cursor: pointer;
}

.form {
    width: 500px;
}

.container {
    padding: 20px
}

.form-message {
    text-align: center;
}

.stories-4{
    background-color: #FFFAF0;
}


@media (min-width: 320px) and (max-width: 374px) {
    .hero-container img{
        width: 100% !important;
        margin-top: -35px;
    }
    .hero-container {
        height: 160px;
        margin-top: 100px !important;
    }
    .video-words {
        margin-top: -130px;
    }
}

@media (min-width: 375px) and (max-width: 414px) {
    .hero-container img{
        width: 100% !important;
        margin-top: -50px;
    }
    .hero-container {
        height: 185px;
        margin-top: 100px !important;
    }
    .video-words {
        margin-top: -150px;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .hero-container img{
        width: 100% !important;
        margin-top: -55px;
    }
    .hero-container {
        height: 200px;
        margin-top: 100px !important;
    }
    .video-words {
        margin-top: -150px;
    }
    .hero-container>h1 {
        font-size: 80px;
    }
}

@media (min-width: 429px) and (max-width: 450px) {
    .hero-container img{
        width: 100% !important;
        margin-top: -54px;
    }
    .hero-container {
        height: 210px;
        margin-top: 100px !important;
    }
    .video-words {
        margin-top: -150px;
    }
    .hero-container>h1 {
        font-size: 80px;
    }
}

@media (min-width: 451px) and (max-width: 599px) {
    .hero-container img{
        width: 100% !important;
        margin-top: -75px;
    }
    .hero-container {
        height: 220px;
        margin-top: 100px !important;
    }
    .video-words {
        margin-top: -170px;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .hero-container img{
        width: 100% !important;
        margin-top: -95px;
    }
    .hero-container {
        height: 300px;
        margin-top: 100px !important;
    }
    .video-words {
        margin-top: -200px;
    }
}

@media (min-width: 700px) and (max-width: 959px) {
    .hero-container img{
        width: 100% !important;
        margin-top: -150px;
    }
    .hero-container {
        height: 340px;
        margin-top: 100px !important;
    }
    .video-words {
        margin-top: -250px;
    }
    .hero-container>h1 {
        font-size: 80px;
    }
}

@media (min-width: 960px) and (max-width: 1023px) {
    .hero-container img{
        width: 100% !important;
        margin-top: -150px;
    }
    .hero-container {
        height: 384px;
        margin-top: 100px !important;
    }
    .video-words {
        margin-top: -350px;
    }
}

@media (min-width: 1025px) {
    .hero-container img{
        width: 100% !important;
        margin-top: -200px;
    }
    .hero-container {
        height: 498px;
    }
    .video-words {
        margin-top: -300px;
    }
    .hero-container>h1 {
        font-size: 80px;
    }
}

@media (min-width: 1200px) {
    .hero-container img{
        width: 100% !important;
        margin-top: -290px;
    }
    .hero-container {
        height: 590px;
    }
    .video-words {
        margin-top: -400px;
    }
}

@media (min-width: 1300px) {
    .hero-container img{
        width: 100% !important;
        margin-top: -250px;
    }
    .hero-container {
        height: 600px;
    }
    .video-words {
        margin-top: -400px;
    }
}

@media (min-width: 1600px) {
    .hero-container {
        height: 560px;
    }
    .video-words {
        margin-top: -600px;
    }
}