.line-bot {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 999;
}

@media (max-width: 319px) {
    .line-bot {
        bottom: 0px;
        right: 0px;
        width: 120px;
    }
}

@media (min-width: 320px) and (max-width: 413px) {
    .line-bot {
        bottom: 0px;
        right: 0px;
        width: 120px;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .line-bot {
        bottom: 0px;
        right: 0px;
        width: 150px;
    }
}

@media (min-width: 429px) and (max-width: 450px) {
    .line-bot {
        bottom: 0px;
        right: 0px;
        width: 150px;
    }
}


@media (min-width: 451px) and (max-width: 599px) {
    .line-bot {
        bottom: 0px;
        right: 0px;
        width: 160px;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .line-bot {
        bottom: 0px;
        right: 0px;
        width: 170px;
    }
}


@media (min-width: 700px) and (max-width: 959px) {
    .line-bot {
        bottom: 0px;
        right: 0px;
        width: 190px;
    }
}

@media (min-width: 960px) and (max-width: 1023px) {
    .line-bot {
        bottom: 0px;
        right: 0px;
        width: 190px;
    }
}

@media (min-width: 1024px) {
    .line-bot {
        bottom: 0px;
        right: 0px;
        width: 190px;
    }
}


@media (min-width: 1025px) {}

@media (min-width: 1400px) {}

@media (min-width: 1600px) {}