* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

body {
  background-color: rgb(255, 250, 240) !important;
}

/* logo */

.tiny-logo {
  width: 3rem;
}

/* text style */

.yellow-text {
  color: #FBBC05;
}

.blue-text {
  color: #1665A8;
}

.light-blue-text {
  color: #35ADD9;
}

.lighter-text {
  color: rgb(48, 48, 48);
}

.lightest-text {
  color: rgb(82, 82, 82);
}

.lightest2-text {
  color: rgb(236, 236, 236);
}

.white-text {
  color: white !important;
}

.no-underline {
  text-decoration: none !important;
}

.centerd-text {
  text-align: center !important;
}

a:hover {
  text-decoration: none !important;
  color: #1665A8 !important;
}

.more-line-height {
  line-height: 1.8em !important;
}

/* font size */

h1 {
  font-size: 30px !important;
}

h2 {
  font-size: 25px !important;
}

h3 {
  font-size: 23px !important;
}

h4 {
  font-size: 20px !important;
}

h5 {
  font-size: 18px !important;
}

h6 {
  font-size: 16px !important;
}

p {
  font-size: 16px !important;
}

span {
  font-size: 16px !important;
}

.bold-underline {
  text-decoration: underline;
  font-weight: bolder;
}

.underline {
  text-decoration: underline;
}

/* typesetting */

.margin-tb-small {
  margin: 30px 0 !important;
}

.margin-tb-medium {
  margin: 50px 0 !important;
}

.centerd {
  margin: 0 auto !important;
}

.margin-top-small {
  margin: 1vh 0 0 0!important;
}

.margin-top-large {
  margin: 4vh 0 0 0!important;
}

.margin-bottom {
  position: relative !important;
  right: 0 !important;
  bottom: 0 !important;
}

.margin-lf-small {
  margin: 0 3vh !important;
}

.margin-bottom-small {
  margin-bottom: 6vh;
}

.margin-bottom-none {
  margin-bottom: 0px !important;
}

@media(min-width: 320px) and (max-width: 400px) {
  h1 {
    font-size: 20px !important;
  }
}

@media (min-width: 401px) and (max-height: 1024px) {
  h1 {
    font-size: 30px !important;
  }
  h2 {
    font-size: 25px !important;
  }
  h3 {
    font-size: 23px !important;
  }
  h4 {
    font-size: 20px !important;
  }
  h5 {
    font-size: 18px !important;
  }
  h6 {
    font-size: 16px !important;
  }
  p {
    font-size: 16px !important;
  }
}

@media (min-width: 600px) and (max-width: 1000px) {
  h1 {
    font-size: 35px !important;
  }
  h2 {
    font-size: 30px !important;
  }
  h3 {
    font-size: 25px !important;
  }
  h4 {
    font-size: 22px !important;
  }
  h5 {
    font-size: 20px !important;
  }
  h6 {
    font-size: 18px !important;
  }
  p {
    font-size: 15px !important;
  }
}

@media (min-width: 1001px) {
  h1 {
    font-size: 45px !important;
  }
  h2 {
    font-size: 38px !important;
  }
  h3 {
    font-size: 30px !important;
  }
  h4 {
    font-size: 25px !important;
  }
  h5 {
    font-size: 23px !important;
  }
  h6 {
    font-size: 20px !important;
  }
  p {
    font-size: 18px !important;
  }
}