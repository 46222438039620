.whole-fivecrobot-container {
    margin-top: 120px !important;
    margin-top: 150px;
}

.fivecrobot-photo-container {
    width: 100%;
    overflow: hidden;
}

.fivecrobot-photo-container img {
    width: 100%;
}

.fivecrobot-photo-container {
    width: 100%;
}

.fivecrobot-photo-container .carousel-control-next-icon, .fivecrobot-photo-container .carousel-control-prev-icon {
    display: none !important;
}
.robot-video-container{
    margin: 0 auto;
}
.robot-video-container img{
    width: 100%;
}

@media (min-width: 320px) and (max-width: 413px) {
    .whole-fivecrobot-container {
        margin-top: 100px !important;
    }
    .fivecrobot-photo-container .carousel {
        height: 200px;
    }
    .fivecrobot-photo-container {
        height: 200px;
    }
    .fivecrobot-container p {
        margin: 20px 10px;
    }
    .fivecrobot-container {
        margin: 50px auto;
    }
    .robot-video-container {
        width: 90%;
    }
    .robot-video-container img{
       margin: 20px auto 10px auto;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .whole-fivecrobot-container {
        margin-top: 100px !important;
    }
    .fivecrobot-photo-container .carousel {
        height: 250px;
    }
    .fivecrobot-photo-container {
        height: 250px;
    }
    .fivecrobot-container p {
        margin: 20px 10px;
    }
    .fivecrobot-container {
        margin: 50px auto;
    }
    .robot-video-container {
        width: 90%;
    }
    .robot-video-container img{
       margin: 30px auto 10px auto;
    }
}

@media (min-width: 429px) and (max-width: 450px) {
    .whole-fivecrobot-container {
        margin-top: 100px !important;
    }
    .fivecrobot-photo-container .carousel {
        height: 250px;
    }
    .fivecrobot-photo-container {
        height: 250px;
    }
    .fivecrobot-container p {
        margin: 20px 10px;
    }
    .fivecrobot-container {
        margin: 60px auto;
    }
    .robot-video-container {
        width: 90%;
    }
    .robot-video-container img{
       margin: 30px auto 10px auto;
    }
}

@media (min-width: 451px) and (max-width: 599px) {
    .whole-fivecrobot-container {
        margin-top: 100px !important;
    }
    .fivecrobot-photo-container .carousel {
        height: 270px;
    }
    .fivecrobot-photo-container {
        height: 300px;
    }
    .fivecrobot-container p {
        margin: 20px 20px;
    }
    .fivecrobot-container {
        margin: 60px auto;
    }
    .robot-video-container {
        width: 90%;
    }
    .robot-video-container img{
       margin: 30px auto 10px auto;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .whole-fivecrobot-container {
        margin-top: 100px !important;
    }
    .fivecrobot-photo-container .carousel {
        height: 350px;
    }
    .fivecrobot-photo-container {
        height: 350px;
    }
    .fivecrobot-container p {
        margin: 20px 30px;
    }
    .fivecrobot-container {
        margin: 60px auto;
    }
    .robot-video-container {
        width: 90%;
    }
    .robot-video-container img{
       margin: 30px auto 10px auto;
    }
}

@media (min-width: 700px) and (max-width: 959px) {
    .whole-fivecrobot-container {
        margin-top: 100px !important;
    }
    .fivecrobot-photo-container .carousel {
        height: 400px;
    }
    .fivecrobot-photo-container {
        height: 400px;
    }
    .fivecrobot-container p {
        margin: 20px 30px;
    }
    .fivecrobot-container {
        margin: 60px auto;
    }
    .robot-video-container {
        width: 90%;
    }
    .robot-video-container img{
       margin: 30px auto 10px auto;
    }
}

@media (min-width: 960px) and (max-width: 1024px) {
    .whole-fivecrobot-container{
        margin-top: 100px !important;
    }
    .fivecrobot-photo-container .carousel {
        height: 450px;
    }
    .fivecrobot-photo-container {
        height: 450px;
    }
    .fivecrobot-container {
        margin: 60px auto;
        width: 80%;
    }
    .robot-video-container {
        width: 90%;
    }
    .robot-video-container img{
       margin: 30px auto 10px auto;
    }
}

@media (min-width: 1025px) {
    .fivecrobot-photo-container .carousel {
        height: 500px;
    }
    .fivecrobot-photo-container {
        height: 500px;
    }
    .fivecrobot-container {
        margin: 100px auto;
        width: 900px;
    }
    .robot-video-container {
        width: 820px;
    }
    .robot-video-container img{
       margin: 30px auto 10px auto;
    }
}

@media (min-width: 1200px) {
    .fivecrobot-photo-container.carousel {
        height: 550px;
    }
    .fivecrobot-photo-container {
        height: 550px;
    }
    .fivecrobot-container {
        width: 1000px;
    }
}

@media (min-width: 1400px) {
    .fivecrobot-photo-container .carousel {
        height: 550px;
    }
    .fivecrobot-photo-container {
        height: 550px;
    }
}

@media (min-width: 1600px) {
    .fivecrobot-photo-container .carousel {
        height: 600px;
    }
    .fivecrobot-photo-container {
        height: 600px;
    }
}