.policy-container {
    margin-top: 120px ;
    margin-bottom: 150px !important;
}

.policy-container h1 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.policy-container p, .policy-container span {
    font-weight: 500;
}

.duty-container {
    margin-top: 120px !important;
}

.duty-container h1 {
    margin-top: 40px;
}

.policy-container h5 {
    font-weight: 500;
}

.red {
    color: red !important;
}

@media (min-width: 320px) and (max-width: 374px) {
    .policy-container {
        margin: 140px 15px 0 15px ;
    }
    .policy-section {
        margin: 40px 0;
    }
    .policy-container h6 {
        margin: 25px 0 10px 0;
        font-weight: 400 !important;
    }
    .policy-container p, .policy-container span {
        font-size: 12px !important;
    }
    .policy-container h5 {
        font-size: 16px !important;
    }
    .policy-container .margin-left {
        text-indent: 2em
    }
    .li-content {
        padding-left: 28px;
    }
    .duty-container {
        padding: 0 15px;
        margin-top: 100px !important;
    }
    .duty-container p {
        font-size: 16px !important;
        margin: 30px 0;
    }
}

@media (min-width: 375px) and (max-width: 413px) {
    .policy-container {
        margin: 130px 20px 0px 20px ;
    }
    .policy-section {
        margin: 40px 0;
    }
    .policy-container h6 {
        margin: 25px 0 10px 0;
        font-weight: 400 !important;
    }
    .policy-container h5 {
        font-size: 16px !important;
    }
    .policy-container p, .policy-container span {
        font-size: 14px !important;
    }
    .policy-container .margin-left {
        text-indent: 2em
    }
    .li-content {
        padding-left: 28px;
    }
    .duty-container {
        padding: 0 15px;
        margin-top: 100px !important;
    }
    .duty-container p {
        font-size: 16px !important;
        margin: 30px 0;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .policy-container {
        margin: 130px 20px 0px 20px ;
    }
    .policy-section {
        margin: 40px 0;
    }
    .policy-container h6 {
        margin: 25px 0 10px 0;
        font-weight: 400 !important;
    }
    .policy-container h5 {
        font-size: 16px !important;
    }
    .policy-container p, .policy-container span {
        font-size: 14px !important;
    }
    .policy-container .margin-left {
        text-indent: 2em
    }
    .li-content {
        padding-left: 28px;
    }
    .duty-container {
        padding: 0 15px;
        margin-top: 100px !important;
    }
    .duty-container p {
        font-size: 16px !important;
        margin: 30px 0;
    }
}

@media (min-width: 429px) and (max-width: 450px) {
    .policy-container {
        margin: 130px 25px 0 25px ;
    }
    .policy-section {
        margin: 40px 0;
    }
    .policy-container h6 {
        margin: 25px 0 10px 0;
        font-weight: 400 !important;
    }
    .policy-container h5 {
        font-size: 17px !important;
    }
    .policy-container p, .policy-container span {
        font-size: 15px !important;
    }
    .policy-container .margin-left {
        text-indent: 2em
    }
    .li-content {
        padding-left: 30px;
    }
    .duty-container {
        padding: 0 15px;
        margin-top: 100px !important;
    }
    .duty-container p {
        font-size: 16px !important;
        margin: 30px 0;
    }
}

@media (min-width: 451px) and (max-width: 599px) {
    .policy-container {
        margin: 130px 25px 0px 25px ;
    }
    .policy-section {
        margin: 40px 0;
    }
    .policy-container h6 {
        margin: 25px 0 10px 0;
        font-weight: 400 !important;
    }
    .policy-container h5 {
        font-size: 17px !important;
    }
    .policy-container p, .policy-container span {
        font-size: 15px !important;
    }
    .policy-container .margin-left {
        text-indent: 2em
    }
    .li-content {
        padding-left: 30px;
    }
    .duty-container {
        padding: 0 15px;
        margin-top: 100px !important;
    }
    .duty-container p {
        font-size: 16px !important;
        margin: 30px 0;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .policy-container {
        margin: 150px 40px 0 40px ;
    }
    .policy-section {
        margin: 40px 0;
    }
    .policy-container h6 {
        margin: 25px 0 10px 0;
        font-weight: 400 !important;
    }
    .policy-container p, .policy-container span {
        font-size: 15px !important;
    }
    .policy-container h5 {
        font-size: 18px !important;
    }
    .policy-container h2 {
        font-size: 25px !important;
    }
    .policy-container .margin-left {
        text-indent: 2em
    }
    .li-content {
        padding-left: 30px;
    }
    .duty-container {
        padding: 0 40px;
        margin-top: 100px !important;
    }
    .duty-container p {
        font-size: 16px !important;
        margin: 30px 0;
    }
}

@media (min-width: 700px) and (max-width: 959px) {
    .policy-container {
        margin: 160px 80px 0px 80px;
    }
    .policy-section {
        margin: 40px 0;
    }
    .policy-container h6 {
        margin: 25px 0 10px 0;
        font-weight: 400 !important;
    }
    .policy-container p, .policy-container span {
        font-size: 15px !important;
    }
    .policy-container .margin-left {
        text-indent: 2em
    }
    .li-content {
        padding-left: 30px;
    }
    .duty-container {
        padding: 0 50px;
        margin-top: 100px !important;
    }
    .duty-container p {
        font-size: 16px !important;
        margin: 35px 0;
    }
}

@media (min-width: 960px) and (max-width: 1099px) {
    .policy-container {
        margin: 180px 120px 0px 120px ;
    }
    .policy-section {
        margin: 40px 0;
    }
    .policy-container h6 {
        margin: 25px 0 10px 0;
        font-weight: 400 !important;
    }
    .policy-container h2 {
        font-size: 30px !important;
    }
    .policy-container h5 {
        font-size: 22px !important;
    }
    .policy-container p, .policy-container span {
        font-size: 16px !important;
    }
    .policy-container .margin-left {
        text-indent: 2em
    }
    .li-content {
        padding-left: 32px;
    }
    .duty-container h1 {
        margin-top: 80px;
    }
    .duty-container {
        padding: 0 60px;
    }
    .duty-container p {
        font-size: 16px !important;
        margin: 35px 0;
    }
}

@media (min-width: 1100px) {
    .policy-container {
        margin: 180px auto 0px auto ;
        width: 750px;
    }
    .policy-section {
        margin: 40px 0;
    }
    .policy-container h6 {
        margin: 25px 0 10px 0;
        font-weight: 400 !important;
    }
    .policy-container p, .policy-container span {
        font-size: 16px !important;
    }
    .policy-container h2 {
        font-size: 30px !important;
    }
    .policy-container h5 {
        font-size: 18px !important;
    }
    .policy-container .margin-left {
        text-indent: 2em
    }
    .li-content {
        padding-left: 32px;
    }
    .duty-container h1 {
        margin-top: 80px;
    }
    .duty-container {
        padding: 0 60px;
    }
    .duty-container p {
        font-size: 16px !important;
        margin: 35px 0;
    }
}

@media (min-width: 1400px) {}

@media (min-width: 1600px) {}