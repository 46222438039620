

.home-container {
    background-color: rgb(255, 250, 240);
    margin-bottom: 150px;
}

.result-container {
    margin: 5vh auto 5vh auto;
    padding: 0 5vh;
    width: 65%;
    text-align: center;
}

.result-link {
    text-align: end;
    margin-right: 14vh;
}

.result-link a {
    font-size: 1vh;
}


.add-line-container {
    background-color: #162430;
    margin: 3vh;
    padding: 3vh;
    border-radius: 10px;
    -webkit-box-shadow: 3px 3px 0 5px #e9e9e9;
    -moz-box-shadow: 3px 3px 0 5px #e9e9e9;
    box-shadow: 0 0 15px 5px #e9e9e9;
}

.add-line-container p {
    color: white !important;
}


.trial-container {
    margin: 3vh 0 10vh 0;
    background-color: #1665A8;
    padding: 5vh;
}

.trial-container p {
    color: white !important;
}

.trial-button {
    width: 130px;
    background-color: #00b900 !important;
}

.usa-container{
    overflow: hidden;
    margin: 0 auto;
}

.usa-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media (min-width: 320px) and (max-width: 374px) {
    .result-container {
        margin: 8vh auto 8vh auto;
        padding: 0;
        width: 90%;
        text-align: center;
    }
    .result-link {
        text-align: end;
        margin-right: 4vh;
    }
    .add-line-container {
        margin: 2vh;
    }
    .usa-container{
        width: 100%;
        padding: 0 10px;
    }
}

@media (min-width: 375px) and (max-width: 414px) {
    .result-container {
        margin: 8vh auto 8vh auto;
        padding: 0;
        width: 90%;
        text-align: center;
    }
    .result-link {
        text-align: end;
        margin-right: 4vh;
    }
    .add-line-container {
        margin: 2vh;
    }
    .usa-container{
        width: 100%;
        padding: 0 10px;
    }
}

@media (min-width: 414px) and (max-width: 428px) {
    .add-line-container {
        margin: 2vh;
    }
    .result-container {
        margin: 50px auto;
        padding: 1vh;
        width: 90%;
    }
    .result-link {
        margin-left: 1vh !important;
    }
    .result-link a {
        font-size: 1vh !important;
    }
     .usa-container{
        width: 100%;
        padding: 0 10px;
    }
}

@media (min-width: 429px) and (max-width: 699px) {
    .add-line-container {
        margin: 3vh;
    }
    .result-container {
        margin: 50px auto 50px auto !important;
        padding: 1vh !important;
        width: 95%;
    }
     .usa-container{
        width: 100%;
        padding: 0 20px;
    }
}

@media (min-width: 700px) and (max-width: 991px) {
    .result-container {
        margin: 50px auto 50px auto !important;
        padding: 1vh !important;
        width: 90%;
    }
    .result-link {
        margin-right: 11vh !important;
    }
     .usa-container{
        width: 100%;
        padding: 0 40px;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .result-container {
        margin: 50px auto 50px auto !important;
        padding: 1vh !important;
        width: 90%;
    }
    .result-link {
        margin-right: 11vh !important;
    }
     .usa-container{
        width: 100%;
        padding: 0 60px;
    }
}

@media (min-width: 1025px) {
    .result-container {
        margin: 10vh auto 10vh auto;
        padding: 0 5vh;
    }
    .trial-container {
        margin: 0 auto 100px auto;
        width: 100%;
    }
    .trial-text-container{
        width: 1000px ;
        margin: 10px auto 40px auto;
    }
    .add-line-container {
        padding: 60px;
        width: 1000px;
        margin: 0 auto;
    }
    .usa-container{
        width: 900px;
    }
    
}

@media (min-width: 1240px) {
    .result-link {
        margin-right: 17vh;
    }
}

@media (min-width: 1400px) {
    .result-link {
        margin-right: 20vh;
    }
    .result-link a {
        font-size: 1vw !important;
    }
}

@media (min-width: 1400px) {
    .result-link {
        margin-right: 24vh;
    }
}